import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "../actions/actions";

const initialState = {
  userData: {
    id: 3,
  },
  showLoginModal: false,
  showRegisterModal: false,
  isLoggedIn: false,
  cartItems: 0,
  wishlistItems: [],
  checkoutCartItems: [],
  newArrivalData: [],
  topSellData: [],
  brands: [],
  sliders: [],
  categories: [],
  featuredData: [],
  notes: [],
  dashboardToken: "",
  wsPrdctEditId: "",
  rsSiteInfo: { siteName: "", siteId: "" },
  logo: "",
  rsshopdata: {},
  rscategory: [],
};

export default handleActions(
  {
    [actions.setUserData](state, { payload }) {
      return produce(state, (state) => {
        state.userData = payload;
      });
    },
    [actions.setNotes](state, { payload }) {
      return produce(state, (state) => {
        state.notes = payload;
      });
    },
    [actions.setShowLoginModal](state, { payload }) {
      return produce(state, (state) => {
        state.showLoginModal = payload;
      });
    },
    [actions.setShowRegisterModal](state, { payload }) {
      return produce(state, (state) => {
        state.showRegisterModal = payload;
      });
    },
    [actions.setIsLoggedIn](state, { payload }) {
      return produce(state, (state) => {
        state.isLoggedIn = payload;
      });
    },
    [actions.clearRedux](state, { payload }) {
      return produce(state, (state) => {
        state.cartQuantity = 0;
        state.wishListQuantity = 0;
        state.userData = {};
      });
    },
    [actions.setCartItems](state, { payload }) {
      return produce(state, (state) => {
        state.cartItems = payload;
      });
    },

    [actions.setWishlistItems](state, { payload }) {
      return produce(state, (state) => {
        state.wishlistItems = payload;
      });
    },

    [actions.setCheckoutCartItems](state, { payload }) {
      return produce(state, (state) => {
        state.checkoutCartItems = payload;
      });
    },
    [actions.setInfoPage](state, { payload }) {
      return produce(state, (state) => {
        state.infoPage = payload;
      });
    },
    [actions.setNewArrivalData](state, { payload }) {
      return produce(state, (state) => {
        state.newArrivalData = payload;
      });
    },
    [actions.setTopSellData](state, { payload }) {
      return produce(state, (state) => {
        state.topSellData = payload;
      });
    },
    [actions.setFeaturedData](state, { payload }) {
      return produce(state, (state) => {
        state.featuredData = payload;
      });
    },
    [actions.setBrands](state, { payload }) {
      return produce(state, (state) => {
        state.brands = payload;
      });
    },
    [actions.setSliders](state, { payload }) {
      return produce(state, (state) => {
        state.sliders = payload;
      });
    },
    [actions.setCategories](state, { payload }) {
      return produce(state, (state) => {
        state.categories = payload;
      });
    },
    [actions.setDashboardToken](state, { payload }) {
      return produce(state, (state) => {
        state.dashboardToken = payload;
      });
    },
    [actions.setWSPrdctEditId](state, { payload }) {
      return produce(state, (state) => {
        state.wsPrdctEditId = payload;
      });
    },
    [actions.setRSSiteInfo](state, { payload }) {
      return produce(state, (state) => {
        return { ...state.rsSiteInfo, ...payload };
      });
    },
    [actions.setRSLogo](state, { payload }) {
      return produce(state, (state) => {
        state.logo = payload;
      });
    },
    [actions.setRSShopData](state, { payload }) {
      return produce(state, (state) => {
        state.rsshopdata = payload;
      });
    },
    [actions.setRSCategory](state, { payload }) {
      return produce(state, (state) => {
        state.rscategory = payload;
      });
    },
  },
  initialState
);
