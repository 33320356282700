/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Select,
  Cascader,
  Menu,
  Collapse,
  Radio,
  Space,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import PriceSlider from "../price-slider";

import "./styles.scss";
import { MailOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const FilterBody = ({
  categories,
  brands,
  filtersSelection,
  selectedFilters,
  handleClearFilter,
  setSelectedFilters,
  setShowFilterDetail,
  showFilterDetail,
  handleChangeParams,
  setToggle,
  toggle,
  setParams,
  params,
  handleChangeSorby
}) => {
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  let isActive = true;
  return (
    <div className="tajira-filters-body-container">
      <Card
        title={
          <div className="title-container">
            <div>Filter</div>
            <div className="actions">
              <Button
                onClick={() => {
                  setParams({
                    pageno: "1",
                    size: "20",
                    category: "",
                    owner: "",
                    max_price: 10000,
                    min_price: 1,
                    sort_by: "",
                  });

                  setShowFilterDetail(!showFilterDetail);
                }}
              >
                Clear All
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setToggle(!toggle);
                  setShowFilterDetail(!showFilterDetail);
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        }
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            {/* <Select placeholder="Select category"> */}
            {/* <Menu
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                items={categories}
                style={{ width: "100%" }}
              /> */}
            <Collapse
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                width: "100%",
              }}
            >
              <Panel header="Select Category" key="1" style={{ width: "100%" }}>
                <Space
                  size={10}
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <Menu
                    style={{
                      width: "100%",
                    }}
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["sub1"]}
                    mode="inline"
                    items={categories}
                  />
                </Space>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            <Text strong>Price</Text>
            <PriceSlider
              filtersSelection={filtersSelection}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              handleChangeParams={handleChangeParams}
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <div style={{paddingBottom:"5px"}}><Text strong>Sort By</Text></div>
            <Radio.Group onChange={handleChangeSorby} value={params?.sort_by}>
              <Space direction="vertical">
                <Radio value={"price_asc"}>Price Low to high</Radio>
                <Radio value={"price_dsc"}>Price high to low</Radio>
              </Space>
            </Radio.Group>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            <Text strong>Rating</Text>
            <Select placeholder="Select rating">
              <Option value={5}>5 Stars</Option>
              <Option value={4}>4 Stars</Option>
              <Option value={3}>3 Stars</Option>
              <Option value={2}>2 Stars</Option>
              <Option value={1}>1 Star</Option>
            </Select>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default FilterBody;
