import React from "react";
import { Slider, InputNumber } from "antd";

const PriceSlider = ({
  setSelectedFilters,
  selectedFilters,
  handleChangeParams,
  handleChageSelectedFilters
}) => {

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleChangePrice = debounce ((min, val0, max , val1) => {

    if(val0 == selectedFilters?.min_price)
    {
     handleChangeParams(max , val1);
     
    }
    else{
     handleChangeParams(min, val0);
    }
  }, 1000);

  return (
    <div>
      <Slider
        range={{ draggableTrack: true }}
        min={1}
        max={10000}
        onChange={(val) => {
          handleChangePrice("min_price", val[0], "max_price", val[1]);

          // setSelectedFilters({
          //   ...selectedFilters,
          //   min_price: val[0],
          //   max_price: val[1],
          // });
        }}
        value={[selectedFilters?.min_price, selectedFilters?.max_price]}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <InputNumber
          style={{
            height: "30px",
            marginRight: "10px",
          }}
          value={selectedFilters?.min_price}
          onChange={(n) => handleChangeParams("min_price", n)}
        />
        -
        <InputNumber
          style={{
            height: "30px",
            marginLeft: "10px",
          }}
          value={selectedFilters?.max_price}
          onChange={(n) => handleChangeParams("max_price", n)}
        />
      </div>
    </div>
  );
};

export default PriceSlider;
