import React from "react";
import "./singleproduct.scss";
import one from "./pngwing.com (1).png";
import two from "./pngwing.com (2).png";
import three from "./pngwing.com.png";
import { MdStarHalf } from "react-icons/md";
import { API_URL } from "../../../utils/constants";
import API from "../../../utils/api";
import { useEffect } from "react";
import { getDataManager } from "../../../utils/helper.functions";
import Spinner from "../../common/spinner";
import { useState } from "react";


export default function SimilarProduct() {
  const Wholesale = new API.Wholesale();
  const [loading, setLoading] = useState(false);
  const [wholesaleProductData, setWholesaleproductdata] = useState([])

  let arr = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 11, 1, 1];

  const getwholesaleproduct = () =>{
    getDataManager(Wholesale?.getWholesaleProduct, setLoading).then((x) => {
      if (x?.status === true) {
        setWholesaleproductdata(x.data.docs)

        // dispatch(setInfoPage(x?.data));
      } else {
        // Messages.error({
        //   content: "Error ocurred while loading info",
        // });
      }
    });
  }

  useEffect(()=>{
    getwholesaleproduct();
  },[])
  return (
    <>
      <div className="main-container">
      {loading && <Spinner />}
        { wholesaleProductData.length>0 &&  wholesaleProductData.map((item) => {
          return (
            <div className="cards">
              <div className="image-div">
                <img src={API_URL + "/" + (item.images.find(item=>item.mainImage == true)).image} alt="" />
              </div>
              <div className="product-deatails">
              <h3 className="rating">
               Sold: {Math.floor(Math.random() * (50 - 15 + 1)) + 15} Pcs | {" "}
                {(Math.random() * (4.9 - 4.1) + 4.1).toFixed(1)} <span><MdStarHalf style={{translate:"0px 2px"}} />
              </span> 
              </h3>
              <h3 className="product-name">{item.title}</h3>
              <p>
                <span className="product-cost">
                  $ {item.price} <span className="ws-text">wholesell price</span>
                </span>
              </p>
              
            </div>
            <div className="buy-btn">Add to Store</div>
              </div>
          );
        })}
      </div>
    </>
  );
}
