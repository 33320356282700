import React from "react";
import "./whyshopwithus.scss";
import {FaShippingFast, FaStarHalfAlt} from "react-icons/fa"
import {MdSecurity} from "react-icons/md";
import {AiOutlineQuestionCircle} from "react-icons/ai";

export default function Whyshopwithus() {
  return (
    <>
      <div className="whyshop_header">
        <div className="whyshop_header_text">Why Shop With us?</div>
      </div>
      <div className="whyshop_maindiv">
        <div className="whyshop_background">
          <div className="whyshop1">
            <FaShippingFast className="whyshop_icon"/>
            <h3>Fast Shipping</h3>
           <p>Standard Shipping to whole Bangladesh</p> 
          </div>
          <div className="whyshop2">
          <MdSecurity className="whyshop_icon"/>
            <h3>Payment Security</h3>
            <p>100% safe and secure payment</p></div>
          <div className="whyshop3">
          <FaStarHalfAlt className="whyshop_icon"/>
            <h3>Quality & Saving</h3>
            <p>Comprehensive quality control and affordable prices</p>
          </div>
          <div className="whyshop4">
          <AiOutlineQuestionCircle className="whyshop_icon"/>
            <h3>Have Questions?</h3>
            <p>24/7 customer service, we are here and happy to help!</p>
          </div>
        </div>
      </div>
    </>
  );
}
