import React, { useEffect } from "react";
import "./customnotification.scss";
import { API_URL } from "../../../utils/constants";
export default function CustomNotification({ product, name }) {


  const images = product?.images?.find((item)=> item?.mainImage == true
  )

  let randomp = Math.floor(Math.random() * (10 - 1)) + 2;


  const shownotification = () => {
    const data = {
      title: `${product?.product?.title?.length <25 ? product?.product?.title : product?.product?.title?.substring(0, 25)+ "..."}`,
      body: `${name} has bought this product`,
      hours: `${randomp} hours ago`,
      imgUrl:
        "https://api.shopyfastbd.com/uploads/images/2024-03-19T11-13-02.520Z-IMG_3996.webp", // Specific image URL for the first notification
    };

    setTimeout(() => {
      createNotification(data.title, data.body, data.imgUrl, data.hours);
    }, 500); // Increase delay for each notification
  };

  function createNotification(title, body, imgUrl, hours) {
    const container = document.querySelector(".notification-container");

    // Create notification element
    const notification = document.createElement("div");
    notification.className = "notification hidden"; // Start hidden to manage transition
    notification.innerHTML = `
            <div class="innernoti">
                <img src="${API_URL}/${images?.image}" alt="Icon" class="notification-icon">
                <div class="text-content">
                    <div class="notification-title">${title}</div>
                    <div class="notification-body">${body}</div>
                    <div class=".notification-hours">${hours}</div>
                </div>
            </div>
        `;

        // <div class="notification-header">
        //                 <span class="notification-title">${title}</span>
        //             </div>
                        // <button class="close-btn">&times;</button>

    // Insert the new notification at the top of the container
    container.prepend(notification); // Ensures new notifications are added at the top

    // Show notification with a delay to allow CSS transition
    setTimeout(() => {
      notification.classList.remove("hidden");
    }, 100);

    // Set auto-hide with cleanup
    setTimeout(() => {
      notification.classList.add("hidden");
      setTimeout(() => {
        if (notification.parentNode) {
          notification.parentNode.removeChild(notification);
        }
      }, 500); // Ensure smooth fading before removal
    }, 8000);

    // Close button functionality
    notification.querySelector(".close-btn").addEventListener("click", () => {
      notification.classList.add("hidden");
      setTimeout(() => {
        if (notification.parentNode) {
          notification.parentNode.removeChild(notification);
        }
      }, 500); // Remove from DOM after transition
    });
  }

  useEffect(() => {
    shownotification();
  }, []);

  return <div class="notification-container"></div>;
}
