import axios from "axios";
import { API_URL, TOKEN, WSTokenfunc } from "../constants";

export default class WholesalerDashboard {
  wholesalerSignin = (payload) => {
    const url = `${API_URL}/api/common/auth/login`;
    return axios
      .post(url, payload)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
         
          if(response.data.user.role == "reseller")
          {
            localStorage.setItem(
              "shopyfastbd-rs-token",
              JSON.stringify(response.data)
            );
          }

          if(response.data.user.role == "customer")
            {
              localStorage.setItem(
                "shopyfastbd-cs-token",
                JSON.stringify(response.data)
              );
            }

          if(response.data.user.role == "wholesaler")
          {
            localStorage.setItem(
              "shopyfastbd-ws-token",
              JSON.stringify(response.data)
            );
          }

          localStorage.setItem(
            "shopyfastbd-token",
            JSON.stringify(response.data)
          );
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  getWholesaleProductlist = ( payload,id, params) => {
    const url = `${API_URL}/api/wholesaler/product?page=${params?.page}&size=500&&owner=true`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getWholesaleOrderlist = ( payload,id, params) => {
    const url = `${API_URL}/api/wholesaler/order`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };



  wholesalerPayment = ( payload) => {
    const url = `${API_URL}/api/wholesaler/order/pay_amount`;
    console.log(url);
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .post(url,payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/wholesaler/product/${id}`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  deleteWSproduct = (payload, id) => {
    const url = `${API_URL}/api/wholesaler/product/${id}`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getWSHomeData = () =>{
    const url = `${API_URL}/api/wholeseller/shop/balance`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  updateProfileInfo = (payload) =>{
    const url = `${API_URL}/api/common/profile`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .put(url,payload,{
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  }

  getProfileInfo = () => {
    const url = `${API_URL}/api/common/profile`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };
}
