import React, { useEffect, useState } from "react";
import "./similarProduct.scss";
import Slider from "react-slick";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { GrStar } from "react-icons/gr";
import Card1 from "../Card/Card1";
import { Link } from "react-router-dom";
import API from "../../../utils/api";
import { API_URL } from "../../../utils/constants";
import { TbCurrencyTaka } from "react-icons/tb";
import { Button, Card, Empty } from "antd";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../utils/custom-hooks";

export default function SliderProduct({
  Similar_product_name = "Random Product",
  products,
}) {
  const slider = React.useRef(null);
  const [sliderPrd, setSliderPrd] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { rsshopdata } = useSelector((state) => {
    return state?.app;
  });

  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  var settings = {
    className: "react-slick",
    infinite: false,
    initialSlide: 0,
    infinite: true,
    speed: 500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 3200,
        settings: {
          slidesToShow:
            sliderPrd?.length > 4
              ? 5
              : sliderPrd?.length > 3
                ? 4
                : sliderPrd?.length > 2
                  ? 3
                  : 2,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow:
            sliderPrd?.length > 4 ? 5 : sliderPrd?.length > 2 ? 3 : 2,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow:
            sliderPrd?.length > 3 ? 4 : sliderPrd?.length > 2 ? 3 : 2,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow:
            sliderPrd?.length > 2 ? 3 : sliderPrd?.length > 1 ? 2 : 1,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: sliderPrd?.length > 1 ? 2 : 1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const windowsize = useWindowSize();

  const handleNavigate = (id) => {
    history.push(`/${rsshopdata?.shop_url}/product/${id}`);
  };


  useEffect(() => {
    setSliderPrd(products);
  }, [products]);

  if (sliderPrd.length == 0) {
    return (
      <>
        <Card>
          <Empty
            description="No product found!"
            style={{ marginTop: "30vh" }}
          />
        </Card>
      </>
    );
  } else {
    return (
      <div style={{ marginBottom: "50px", marginLeft: "15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="slider_title">
            {Similar_product_name}
          </h4>
          <div style={{ translate: "-23px 0px" }}>
            <span
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "500",
                fontFamily: "monospace",
              }}
            >
              <button style={{padding:"5px 5px 0px 5px",background:'#397D86', color:"white", border:".3px solid grey", borderRadius:"2px", fontWeight:600}}><Link to={`/${routepaths[1]}/product`}>View All</Link></button>
            </span>
            {/* <button
              onClick={() => slider?.current?.slickPrev()}
              style={{
                background: "none",
                border: "none",
                fontSize: "12px",
                border: "2px solid #397D86",
                borderRadius: "350px",
                marginRight: "3px",
              }}
            >
              <AiOutlineArrowLeft style={{ translate: "0px 1px" }} />
            </button>
            <button
              onClick={() => slider?.current?.slickNext()}
              style={{
                background: "none",
                border: "none",
                fontSize: "12px",
                border: "2px solid #397D86",
                borderRadius: "35px",
                margin: "0px",
              }}
            >
              <AiOutlineArrowRight style={{ translate: "0px 1px" }} />
            </button> */}
          </div>
        </div>

        <Slider ref={slider} {...settings}>
          {sliderPrd &&
            sliderPrd?.length > 0 &&
            sliderPrd.map((item, index) => {
              // const percentage = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
              const percentage = item?.price < 200 ? 20 : (item?.price < 300 ? 25 : (item?.price < 400 ? 30 : (item?.price < 500 ? 35 : (item?.price < 600 ? 38 : (item?.price < 700 ? 32 : 28)))))
              const calculatedPercentage = (percentage / 100) * item.price;
              const lessprice = Math.floor(calculatedPercentage);


              var numbers = [3.9, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5];
              var randomIndex = Math.floor(Math.random() * numbers.length);
              // var rating = numbers[randomIndex];


              var rating = item?.price < 200 ? 4.2 : (item?.price < 300 ? 4.3 : (item?.price < 400 ? 4.4 : (item?.price < 500 ? 4.5 : (item?.price < 600 ? 4.6 : (item?.price < 700 ? 4.7 : 4.8)))))

              return (
                <div
                  onClick={() => {
                    handleNavigate(item._id);
                  }}
                >
                  <div className="cards" style={{ background: "red" }}>
                    <div className="discount">-{percentage}%</div>
                    <img
                      src={`${API_URL}/${item.images.find((item) => item.mainImage == true).image
                        }`}
                      alt="picture"
                    />
                    <div className="card-details">
                      <div className="product-sold-rating">
                        {" "}
                        <span className="product-sold">
                          {percentage + 6} sold{" "}
                        </span>
                        <span className="product-rating">
                          |{" "}
                          <GrStar
                            style={{ translate: "0px 2px", color: "grey" }}
                          />{" "}
                          {rating}
                        </span>
                      </div>
                      <div className="product-name">
                        {item?.product?.title?.length > 31
                          ? item?.product?.title?.substring(0, `${windowsize?.width < 380 ? 18 : (windowsize?.width < 480 ? 34 : 42)}`
                          ) + "..."
                          : item?.product?.title}

                      </div>
                      <div className="product-cost">
                        <TbCurrencyTaka className="bigger-tk-icon"/>
                        <span> {item?.size_price?.length > 0 ? item?.size_price[0].price : item?.price} </span>
                        <span className="price-less">
                          <TbCurrencyTaka className="smaller-tk-icon"/>
                          {lessprice + (item?.size_price?.length > 0 ? item?.size_price[0].price : item?.price)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    );
  }
}

{
  /* <div>
              <div className="cards">
                <div className="discount">-40%</div>
                <img src={item} alt="picture" />
                <div className="card-details">
                  <div className="product-sold-rating">
                    {" "}
                    <span className="product-sold">141 sold </span>
                    <span className="product-rating">
                      | <GrStar style={{translate:"0px 2px", color:"grey"}}/> 4.1
                    </span>
                    
                  </div>
                  <div className="product-name">Polo T-Shirt</div>
                  <div className="product-cost">
                    <span>$500 </span>
                    <span className="price-less">$900</span>
                  </div>
                </div>
                <div className="buy-btn">Buy Now</div>
              </div>
            </div> */
}
