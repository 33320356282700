import axios from "axios";
import { API_URL, TOKEN } from "../constants";
export default class Home {
  getFeaturedProducts = (payload = {}) => {
    let url = `${API_URL}api/v1/store/public/products/?limit=${
      payload?.limit || 12
    }&offset=${payload?.offset || 0}&category=${
      payload?.category || ""
    }&is_featured=true&min_price=${payload?.min_price || ""}&max_price=${
      payload?.max_price || ""
    }&rating=${payload?.rating || ""}`;
    return axios
      .get(url, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  getNewlyArrivedProducts = (payload = {}) => {
    let url = `${API_URL}api/v1/store/public/products/?limit=${
      payload?.limit || 12
    }&offset=${payload?.offset || 0}&category=${
      payload?.category || ""
    }&new_arrival=true`;
    return axios
      .get(url, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  getTopSaleProducts = (payload = {}) => {
    let url = `${API_URL}api/v1/store/public/products/?limit=${
      payload?.limit || 12
    }&offset=${payload?.offset || 0}&category=${
      payload?.category || ""
    }&item_type=top_sale`;
    return axios
      .get(url, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  subscribeToEmail = (payload) => {
    const url = `${API_URL}api/v1/subscription/email/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };
}
