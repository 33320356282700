import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Auth {
  verifyCode = (payload) => {
    const url = `${API_URL}/api/common/auth/verify-code`;
    return axios
      .put(url, payload)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  resendsendCode = (payload) => {
    const url = `${API_URL}/api/common/auth/send-code`;
    return axios
      .post(url, payload)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  userregistration = (payload) => {
    const url = `${API_URL}/api/customer/auth/register`;
    return axios
      .post(url, payload)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };
}
