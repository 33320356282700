import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataManager } from "../../../utils/helper.functions";
import API from "../../../utils/api";
import ReturnPolicy from "../../common/returnPolicy/returnPolicy";
import HowToBuy from "../../common/howToBuy/HowToBuy";
import { Button, Image, Modal, Rate } from "antd";
import ImageSlider from "../../common/imageSlider/imageSlider";
import { API_URL, CSbearerToken } from "../../../utils/constants";
import "./rsSingleProduct.scss";
import {
  setCartItems,
  setRSCategory,
  setRSLogo,
  setRSShopData,
  setShowLoginModal,
  setShowRegisterModal,
} from "../../../utils/redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SimilarProduct from "../../poshcart/similarproduct/similarProduct";
import { Notification } from "../../../utils/helper.functions";
import Spinner from "../../common/spinner";
import CustomNotification from "../../common/custom-notification/customNotification";
import { CiLock } from "react-icons/ci";
import { SlLike } from "react-icons/sl";
import { CiHeadphones } from "react-icons/ci";
import { MdDirectionsCar } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { TiShoppingCart } from "react-icons/ti";
import { MdShoppingCartCheckout } from "react-icons/md";
import SliderProduct from "../../poshcart/similarproduct/similarProduct";
import { Helmet } from "react-helmet";
import CommonProduct from "../../poshcart/commonProduct/commonProduct";

export default function RsSingleProduct() {
  var intervalId
  let params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [shopdata, setSingleShopData] = useState({});
  const [price, setPrice] = useState();
  const [selectcolorid, setSelectColorId] = useState();
  const [uniqueColor, setUniqueColor] = useState([]);
  const [priceselectid, setPriceselectid] = useState();
  const [mainImage, setmainimage] = useState();
  const [imageSelectedId, setimageSelectedId] = useState();
  const { rsshopdata } = useSelector((state) => state.app);
  const [CustomNotificationdata, setCustomNotificationdata] = useState({
    toggle: false,
    title: "",
    body: "",
    imageUrl: "",
  });
  const pathname = location.pathname.split("/");
  const shopname = pathname[1];

  console.log("shop name ===========", shopname);
  const [desp, setDesp] = useState({
    description: false,
    howtobuy: false,
    returnpolicy: true,
  });
  const [allparams, setParams] = useState({
    pageno: "1",
    size: "20",
    category: "",
    owner: "",
    max_price: 10000,
    min_price: 1,
    sort_by: "price_asc",
  });

  const [cartdetails, setcartdetails] = useState({ color: "", size: "" });

  const Reseler = new API.Reseler();
  const history = useHistory();

  const whatsappURL = `https://api.whatsapp.com/send?phone=88${rsshopdata?.contact_number}&text=whatsapp+number&fbclid=IwY2xjawExzM5leHRuA2FlbQIxMAABHUcgpTZFip-IzCdmEBMlc8fIAnq-z9Vmwqi8LUsAQFrY-3gn4_tclReOqg_aem_aOiAxLHVorB2g-T8nQvbtg`;

  const getReselershopinfo = async () => {
    let shopdata = await getDataManager(
      Reseler?.getReselershopinfo,
      setLoading,
      {},
      shopname
    );
    if (shopdata?.status == true) {
      // dispatch(setRSSiteInfo());
    }

    return shopdata;
  };
  const getsingleProduct = async () => {
    let shopdata = await getDataManager(
      Reseler?.getReselersingleProduct,
      setLoading,
      {},
      params?.productid
    );

    let colorobj = shopdata.data.images.map((item) => item.color);
    let unique = [...new Set(colorobj)];
    setUniqueColor(unique);
    let mainimage = shopdata.data.images.find((item) => item.mainImage == true);
    setmainimage(mainimage.image);

    if (shopdata?.data?.product?.priceVarient == true) {
      setPrice(shopdata?.data?.size_price[0]?.price);
    } else {
      setPrice(shopdata?.data?.price);
    }

    setSingleShopData(shopdata.data);
  };
  const changeDescription = (value) => {
    if (value == 1) {
      setDesp({
        ...desp,
        description: true,
        howtobuy: false,
        returnpolicy: false,
      });
    }
    if (value == 2) {
      setDesp({
        ...desp,
        description: false,
        howtobuy: true,
        returnpolicy: false,
      });
    }
    if (value == 3) {
      setDesp({
        ...desp,
        description: false,
        howtobuy: false,
        returnpolicy: true,
      });
    }
  };
  const priceChange = (size, id) => {
    setcartdetails({ ...cartdetails, size: size });
    if (shopdata?.product?.priceVarient == true) {
      let temppriceobj = shopdata?.size_price?.find(
        (item) => item.size == size
      );
      setPrice(temppriceobj.price);
      setPriceselectid(id);
    } else {
  
      setPriceselectid(id);
    }
  };
  const selectColor = (color, index) => {
    let tempobj = shopdata.images.find((item) => item.color == color);
    setimageSelectedId(tempobj._id);
    setmainimage(tempobj.image);
    setSelectColorId(index);

    setcartdetails({ ...cartdetails, color: color });
  };


  const addToCart = () => {
    if (CSbearerToken.length < 20) {
      Notification("warning", "Login to add to cart");
      dispatch(setShowLoginModal(true));
      return;
    }

   
    if (( uniqueColor[0]!="" && uniqueColor?.length > 0 ) && cartdetails.color == "") {

      console.log("color");

      Notification("warning", "Select color and size to buy this product");
      return;
    }
    
    if ((shopdata?.size_price?.length > 0 || shopdata?.product?.size?.length > 0) && cartdetails.size == "") {
     
      console.log("size");

      Notification("warning", "Select color and size to buy this product");
      return;
    }


    getDataManager(Reseler?.userAddToCart, setLoading, {
      item_id: params?.productid,
      item_count: 1,
      size: cartdetails?.size,
      color: cartdetails?.color,
    }).then((res) => {
      if (res?.code == 200) {
        dispatch(setCartItems(1));
        Notification("success", "Added to cart");
      }
    });
  };
  const buynow = () => {
    
    if (CSbearerToken.length < 20) {
      Notification("warning", "Login to buy this product");
      dispatch(setShowLoginModal(true));
      return;
    }
    
    if (( uniqueColor[0]!="" && uniqueColor?.length > 0 ) && cartdetails.color == "") {

      console.log("color");

      Notification("warning", "Select color and size to buy this product");
      return;
    }
    
    if ((shopdata?.size_price?.length > 0 || shopdata?.product?.size?.length > 0) && cartdetails.size == "") {
     
      console.log("size");

      Notification("warning", "Select color and size to buy this product");
      return;
    }
    
    getDataManager(Reseler?.userAddToCart, setLoading, {
      item_id: params?.productid,
      item_count: 1,
      size: cartdetails?.size,
      color: cartdetails?.color,
    }).then((res) => {
      if (res?.code == 200) {
        dispatch(setCartItems(1));
        // Notification("success", "Added to cart");
        let shopname = window.location.href.split("/")[3];
        history.push(`/${shopname}/shopping-cart`);
      }
    });


  };
  const [productData, setProductData] = useState([]);
  const getReselerAllProduct = async (params, id) => {
    let productdata = await getDataManager(
      Reseler?.getReselerAllProduct,
      setLoading,
      {},
      id,
      params
    );

    console.log("product ===============", productdata);

    if (productdata?.status == true) {
      setProductData(productdata?.data?.data);
      randomnotificationshow();
    }
  };
  const randomnotificationshow = () => {
    intervalId = setInterval(() => {
      RandomNotification();
    }, 15000);
  };
  var tempbool = false;
  const RandomNotification = () => {
    let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
    let randomn = Math.floor(Math.random() * (10 - 0)) + 0;
    let names = [
      "Mamun",
      "Rafi",
      "Rubel",
      "Rasel",
      "Rahul",
      "Rimi",
      "Ritu",
      "Nili",
      "Ruman",
      "Mahi",
      "Mitu",
    ];
    let togg = [false, true, false, true, false];
    const product = productData[randomp];
    const randomtogg = togg[randomn];

    const randomname = names[randomn];

    if (tempbool === randomtogg) {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle: !randomtogg,
        name: randomname,
      });
      tempbool = !randomtogg;
    } else {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle: randomtogg,
        name: randomname,
      });
      tempbool = randomtogg;
    }
  };
  let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
  const notiproduct = productData[randomp];

  useEffect(() => {
    getsingleProduct();

    return () => {
      clearInterval(intervalId);
    };
  }, [params?.productid]);

  useEffect(async () => {
    let shopdata = await getReselershopinfo();

    dispatch(setRSLogo(shopdata?.data?.shop_data?.shop_logo));
    dispatch(setRSShopData(shopdata?.data?.shop_data));
    dispatch(setRSCategory(shopdata?.data?.category));
    getReselerAllProduct(allparams, shopdata?.data?.shop_data?._id);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  console.log({ shopdata });

  return (
    <>
      <div className="wsd-flex-container">
        {CustomNotificationdata?.toggle && (
          <CustomNotification
            product={notiproduct}
            name={CustomNotificationdata?.name}
          />
        )}
        <div className="wsd-box">
          <div className="wsd-left-box">
            <div className="wsd-main-image">
              <Image
                src={`${API_URL}/${mainImage}`}
                className="wsd-main-image-src"
                alt="main-image"
              />
            </div>
            <div className="wsd-slider-image">
              {shopdata && shopdata?.images?.length > 0 && (
                <ImageSlider
                  imagesArr={shopdata.images}
                  setmainimage={setmainimage}
                  imageSelectedId={imageSelectedId}
                  setimageSelectedId={setimageSelectedId}
                />
              )}
            </div>
          </div>
        </div>

        <div className="wsd-box">
          <div className="wsd-right-box">
            <div className="wsd-right-box-div">
              <p className="wsd-rating">
                <Rate />{" "}
              </p>
              <p className="wsd-title">{shopdata?.product?.title}</p>
              <p className="usp-price">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_z2ZYQJeaBWlUL1MdxHi-SOXE42vrncqGqQ&usqp=CAU"

                  className="wsd-price-icon"
                />
                {price}{" "}
                <>
                  {shopdata && shopdata?.size_price?.length > 0 ?
                    <span className="less_price">
                      {shopdata?.size_price[0]?.price < 500
                        ? shopdata?.size_price[0]?.price + 200
                        : shopdata?.size_price[0]?.price < 1000
                          ? shopdata?.size_price[0]?.price + 250
                          : shopdata?.size_price[0]?.price < 2000
                            ? shopdata?.size_price[0]?.price + 300
                            : shopdata?.size_price[0]?.price + 400}
                    </span>
                    :

                    <span className="less_price">
                      {shopdata?.price < 500
                        ? shopdata?.price + 200
                        : shopdata?.price < 1000
                          ? shopdata?.price + 250
                          : shopdata?.price < 2000
                            ? shopdata?.price + 300
                            : shopdata?.price + 400}
                    </span>
                  }
                </>

              </p>

              {shopdata?.product?.productVarient == true && (
                <h3 className="wsd-colors">
                  {" "}
                  Colors:
                  {uniqueColor &&
                    uniqueColor?.map((item, index) => {
                      return (
                        <span
                          key={index}
                          className="wsd-color"
                          style={{
                            background: `${item}`,
                            outlineColor: `${index == selectcolorid ? "black" : "#fafafa"
                              }`,
                          }}
                          onClick={() => selectColor(item, index)}
                        ></span>
                      );
                    })}
                </h3>
              )}

              <h3 className="wsd-size">
                <span>{shopdata?.product?.size?.length > 0 && "Size:"}</span>
                {shopdata?.product?.priceVarient == true
                  ? shopdata?.size_price?.length > 0 &&
                  shopdata?.size_price.map((item, index) => {
                    return (
                      <div
                        className="wsd-sp-size-text"
                        style={{
                          background: `${priceselectid == index ? "#397d86" : "white"
                            }`,
                          color: `${priceselectid == index ? "white" : "black"
                            }`,
                        }}
                        onClick={() => {
                          priceChange(item.size, index);
                        }}
                      >
                        {item.size}
                      </div>
                    );
                  })
                  : shopdata?.product?.size?.length > 0 &&
                  shopdata?.product?.size.map((item, index) => {
                    return (
                      <div
                        className="wsd-sp-size-text"
                        style={{
                          background: `${priceselectid == index ? "#397d86" : "white"
                            }`,
                          color: `${priceselectid == index ? "white" : "black"
                            }`,
                        }}
                        onClick={() => {
                          priceChange(item, index);

                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
              </h3>

              {shopdata?.product?.quantity && (
                <h3 className="wsd-sp-qty">
                  <span style={{ marginRight: "4px" }}>
                    Quantity: {shopdata?.product?.quantity}
                  </span>
                </h3>
              )}

              <div className="wsd-cartBtn" onClick={addToCart}>
                <TiShoppingCart
                  style={{
                    translate: "0px 2px",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />{" "}
                Add to cart
              </div>
              <div className="wsd-cartBtn" onClick={buynow}>
                {" "}
                <MdShoppingCartCheckout
                  style={{
                    translate: "0px 2px",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
                Buy Now
              </div>
              <br />
              <div className="sp-icon-wrapper">
                <a href={`tel:88${rsshopdata?.contact_number}`} className="row">
                  <div className="wsd-phone-icon">
                    <IoCallOutline
                      style={{ translate: "0px 2px", marginRight: "5px" }}
                    />{" "}
                    <span>CALL US : {rsshopdata?.contact_number}</span>
                  </div>{" "}
                </a>
              </div>
              <br />

              <div className="sp-icon-wrapper">
                <a href={whatsappURL} className="row">
                  <div className="wsd-whatsapp-icon">
                    <FaWhatsapp
                      style={{
                        translate: "0px 4px",
                        marginRight: "5px",
                        fontSize: "18px",
                      }}
                    />{" "}
                    <span>CHAT WITH US ON WHATSAPP</span>
                  </div>{" "}
                </a>
              </div>

              <div style={{ fontSize: "18px", fontWeight: 600, padding: "30px 0px 20px 0px", display: "flex", flexDirection: "column", flexWrap: "wrap", gap: "15px" }}>
                <div>*ঢাকার ভিতরে ডেলিভারি চার্জ ৮০ টাকা </div>
                <div>*ঢাকার আশেপাশে ডেলিভারি চার্জ ১২০ টাকা
                </div>
                <div>*ঢাকার বাহিরে ডেলিভারি চার্জ ১৩০ টাকা
                </div>

              </div>

              <div className="wsd-desp">
                <div
                  className="wsd-ws-sp-div"
                  value="wsd-description"
                  onClick={() => changeDescription(1)}
                  style={{
                    // borderRight: "2px solid black",
                    background: `${desp.description == true ? "#397d86" : "white"
                      }`,
                    color: `${desp.description == true ? "white" : "black"}`,
                    cursor: "pointer",
                  }}
                >
                  Description
                </div>{" "}
                <div
                  className="wsd-ws-sp-div"
                  name="howtobuy"
                  onClick={() => changeDescription(2)}
                  style={{
                    // borderRight: "2px solid black",
                    background: `${desp.howtobuy == true ? "#397d86" : "white"
                      }`,
                    color: `${desp.howtobuy == true ? "white" : "black"}`,
                    cursor: "pointer",
                  }}
                >
                  How To Buy
                </div>{" "}
                <div
                  name="returnpolicy"
                  onClick={() => changeDescription(3)}
                  style={{
                    background: `${desp.returnpolicy == true ? "#397d86" : "white"
                      }`,
                    color: `${desp.returnpolicy == true ? "white" : "black"}`,
                    cursor: "pointer",
                  }}
                  className="wsd-ws-sp-div"
                >
                  Return Policy
                </div>
                {desp.description && (
                  <p className="wsd-desp-text">
                    <div
                      className="wsd-desp-text-div"
                      dangerouslySetInnerHTML={{
                        __html: shopdata.description,
                      }}
                    />
                  </p>
                )}
                {desp.howtobuy && (
                  <p className="wsd-desp-text">
                    <HowToBuy />
                  </p>
                )}
                {desp.returnpolicy && (
                  <p className="wsd-desp-text">
                    <ReturnPolicy />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CommonProduct
          Similar_product_name="Hot selling"
          products={
            shopdata?.hot_selling?.length > 0 ? shopdata?.hot_selling : []
          }
          routename={shopname}
        />

        <CommonProduct
          Similar_product_name="Recommended Product"
          products={
            shopdata?.recommended_product?.length > 0
              ? shopdata?.recommended_product
              : []
          }
          routename={shopname}

        />

        <CommonProduct
          Similar_product_name="Trending Product"
          products={
            shopdata?.trending_product?.length > 0
              ? shopdata?.trending_product
              : []
          }
          routename={shopname}
        />
      </div>

      <div>
        <SinglepageDetails />
      </div>

      <div className="bottom_nav">
        <div
          className="bottom_nav_div"
          style={{
            color: "#397D86",
            background: "#d1cdcd",
            border: "8px solid white",
            cursor: "pointer",
          }}
          onClick={addToCart}
        >
          Add to cart
        </div>

        <div
          className="bottom_nav_div"
          style={{
            color: "white",
            background: "#397D86",
            border: "8px solid white",
            cursor: "pointer",
          }}
          onClick={buynow}
        >
          Buy now
        </div>
      </div>
    </>
  );
}

export const SinglepageDetails = () => {
  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}
      className="singlepagedetailsdiv"
    >
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="singlepagedetailsSubdiv"
      >
        <SlLike
          style={{
            fontSize: "50px",
            padding: "10px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        />
        <span style={{ marginLeft: "25px" }}>
          হাই-কোয়ালিটি পণ্য <br /> Enjoy top quality item for less.
        </span>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="singlepagedetailsSubdiv"
      >
        <CiHeadphones
          style={{
            fontSize: "50px",
            padding: "10px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        />
        <span style={{ marginLeft: "25px" }}>
          24/7 লাইভ চ্যাট <br /> Get instance assistance
        </span>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="singlepagedetailsSubdiv"
      >
        <MdDirectionsCar
          style={{
            fontSize: "50px",
            padding: "10px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        />
        <span style={{ marginLeft: "25px" }}>
          এক্সপ্রেস শিপিং <br /> Fast & reliable delivery options
        </span>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="singlepagedetailsSubdiv"
      >
        <CiLock
          style={{
            fontSize: "50px",
            padding: "10px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        />
        <span style={{ marginLeft: "25px" }}>
          সিকিউর পেমেন্ট <br /> Multiple safe payment methods
        </span>
      </div>
    </div>
  );
};
