import React, { useEffect, useState } from "react";
import "./singleproduct.scss";
import {
  ShoppingCartOutlined,
  HeartOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import colorselecticon from "../../../images/poshcarticon/icons8-circle-30.png";
import { Button, Input, InputNumber, Radio, Rate, notification } from "antd";
import SimilarProduct from "../similarproduct/similarProduct";
import ImageSlider from "../imageSlider/imageSlider";
import { API_URL } from "../../../utils/constants";
import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";
import Spinner from "../../common/spinner";
import HowToBuy from "../../common/howToBuy/HowToBuy";
import ReturnPolicy from "../../common/returnPolicy/returnPolicy";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { AiOutlineCheck } from "react-icons/ai";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { saveAs } from "file-saver";
import { FaUpload } from "react-icons/fa";
import ImageUpload from "./imageUpload";
import JoditEditor from "jodit-react";
import Editor from "../../jodit-editor";

const Description = ({ singleProduct, setSingleProduct }) => {
  const [content, setContent] = useState("");
  const handleUpdate = (event) => {
    const editorContent = event?.target?.innerHTML;
    setContent(editorContent);
    setSingleProduct((prev) => ({ ...prev, description: event }));
  };
  useEffect(() => {
    if (singleProduct.description) {
      if (typeof singleProduct.description === "string") {
        setContent(singleProduct.description);
      } else {
        setContent(singleProduct.description.toString());
      }
    }
  }, [singleProduct.description]);

  return (
    <div>
      <Editor
        value={content}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default function RsSingleProduct() {
  const Wholesale = new API.Wholesale();
  const Reseler = new API.Reseler();
  const [loading, setLoading] = useState(false);
  const [singleProduct, setSingleProduct] = useState({});
  const [mainImage, setmainimage] = useState();
  const [imageSelectedId, setimageSelectedId] = useState();
  const [uniqueColor, setUniqueColor] = useState();
  const [selectcolorid, setSelectColorId] = useState();
  const [profit, setProfit] = useState("");
  const [priceselectid, setPriceselectid] = useState();
  const [reslrImage, setReslrImage] = useState([]);
  const [checkArray, setCheckArray] = useState([]);
  const [priceErr, setPriceError] = useState(false);
  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();
  const [profitDiff, setProfitDiff] = useState();
  const [desp, setDesp] = useState({
    description: true,
    howtobuy: false,
    returnpolicy: false,
  });
  const [rslrSnglPrd, setRslrSnglPrd] = useState({
    price: "",
    product: "",
    size_price: "",
    description: "",
    images: "",
  });
  const Notification = (type, mesg, desp) => {
    notification[type]({
      message: mesg,
      description: desp,
    });
  };
  const downloadImage = (imgUrl) => {
    saveAs(imgUrl, "download.jpg"); // Put your image URL here.
  };
  const handleChangePrice = (e) => {
    const regex = /^\s*$|^\d+$/;

    if (regex.test(e.target.value)) {
      let price = Number(e.target.value);
      if (price > minPrice) {
        setProfit(price);
        setPriceError(false);
      } else {
        setProfit(price);
        setPriceError(true);
        Notification("error", "Price should  be in between the range");
      }
    }
  };
  const getSingleProduct = () => {
    getDataManager(
      Wholesale?.getSingleProduct,
      setLoading,
      {},
      "65daccf1f59c1768563994c6"
    ).then((x) => {
      if (x?.status === true) {
        setSingleProduct((prev) => ({ ...prev, ...x.data }));
        setSingleProduct((prev) => ({
          ...prev,
          images: [
            ...prev.images.map((item) => ({
              ...item,
              is_rslr_image: false,
              isSeleced: false,
              mainImage:false
            })),
          ],
        }));

        let colorobj = x.data.images.map((item) => item.color);
        let unique = [...new Set(colorobj)];
        setUniqueColor(unique);
        let mainimage = x.data.images.find((item) => item.mainImage == true);
        setRslrSnglPrd({ ...rslrSnglPrd, price: x.data.price });
        setMinPrice(x?.data?.reseller_price?.min_sell_price);
        setMaxPrice(x?.data?.reseller_price?.max_sell_price);
        setProfitDiff(
          x?.data?.reseller_price?.max_sell_price -
            x?.data?.reseller_price?.min_sell_price
        );
      } else {
     
      }
    });
  };
  const handleSelectMainImage = (id, _id) => {

    let tempsingleprduct = singleProduct.images.map((item, index) => {
      if (id == index) {
       
        return { ...item, mainImage: true };
      } else {
        return { ...item, mainImage: false };
      }
    });

    setSingleProduct({ ...singleProduct, images: [...tempsingleprduct] });
  };
  const addImage = (id) => {
    let temp = singleProduct.images.map((item, index)=>{
      if(index == id)
      {
        if(item.isSeleced == false)
        return {
          ...item, isSeleced: true
        }
        else
        return {
          ...item, isSeleced: false
        }
      }
      else{
        return item;
      }
    })

    setSingleProduct({...singleProduct, images: [...temp]});

  };
  const error = () => {
    let flag = false;
    if (profit < minPrice) {
      flag = true;
      setPriceError(true);
      Notification("error", `Profit should be greater than ${minPrice} `);
      return flag;
    }
    
      let mainimageflag = false;
      let selecimgfalg = false;
      let copyrslrimage = singleProduct.images.map((item) => {
        if (item.mainImage == true && item.isSeleced == true) {
          mainimageflag = true;
        }
        if(item.isSeleced == true)
        {
          selecimgfalg = true;
        }
        return item;
      });
      if (selecimgfalg == false) {
        flag = true;
        Notification("error", `Select atleast one Image`);
        return flag;
      }
      if (mainimageflag == false) {
        flag = true;
        Notification("error", `Select a main image from the selected images`);
        return flag;
      }     
    
    return flag;
  };
  const handleSubmit = () => {
    let errResult = error();
    if (errResult) {
      return;
    } else {
      Notification("success", `successfull`);
    }
    let payload = {
      product: "65daccf1f59c1768563994c6",
      profit: profit,
      images: singleProduct.images,
      description: singleProduct.description
    };


    getDataManager(
      Reseler?.postReselrSingleProduct,
      setLoading,
      payload,
      "65daccf1f59c1768563994c6"
    ).then((x) => {
      if (x?.status === true) {
      } else {
      }
    });
  };
  
  useEffect(() => {
    getSingleProduct();
  }, []);

  if (loading) {
    return <Spinner />;
  } else
    return (
      <>
        <div className="flex-container">
          <div className="box">
            <div className="left-box">
              {/* <div className="main-image">
                <img
                  src={`${API_URL}/${mainImage}`}
                  className="main-image-src"
                  alt="main-image"
                />
              </div> */}
              <div className="image-picker">
                {singleProduct?.images?.length > 0 && (
                  <div className="image-list">
                    {singleProduct?.images?.map((item, index) => {
                      return (
                        <div
                          className="image-list-single-div"
                          style={{
                            border: `${
                              item.isSeleced == true
                                ? "5px solid black"
                                : "1px solid grey"
                            }`,
                            padding: "10px",
                          }}
                        >
                          {item.isSeleced == true && (
                            <div className="selected-icon">
                              <AiOutlineCheck
                                style={{
                                  color: "green",
                                  background: "white",
                                  border: "2px solid green",
                                  borderRadius: "50%",
                                  fontSize: "45px",
                                  padding: "5px",
                                  translate: "15px -25px",
                                }}
                              />
                            </div>
                          )}

                          <img
                            src={`${API_URL}/${item.image}`}
                            alt="no image"
                            className="selected-single-image"
                            onClick={() => {
                              addImage(index);
                            }}
                          />
                          <div style={{ background: "#081e40" }}>
                            <span
                              style={{
                                marginRight: "4px",
                                color: "black",
                                fontWeight: "bold",
                                marginLeft: "2px",
                                color: "white",
                              }}
                            >
                              Main:
                            </span>
                            <Radio.Group
                              size="large"
                              onChange={(e) => {
                                handleSelectMainImage(index, item._id);
                              }}
                              value={item?.mainImage}
                              style={{
                                textAlign: "center",
                                margin: "auto",
                                fontSize: "25px",
                                translate: "0px 0px",
                              }}
                            >
                              <Radio size="large" value={true}></Radio>
                            </Radio.Group>

                            <PiDownloadSimpleBold
                              style={{
                                float: "right",
                                background: "white",
                                padding: "8px",
                                fontSize: "40px",
                                alignItems: "cener",
                                alignContent: "center",
                                color: "black",
                              }}
                              onClick={() => {
                                downloadImage(`${API_URL}/${item.image}`);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* <div>
                  <div> Upload</div>
                  <div>
                    {" "}
                    <FaUpload />
                  </div>
                </div> */}
                <ImageUpload
                  singleProduct={singleProduct}
                  setSingleProduct={setSingleProduct}
                />
              </div>
            </div>
          </div>

          <div className="box">
            <div className="right-box">
              <div>
                <p className="rating">
                  <Rate />{" "}
                </p>
                <p className="title">{singleProduct.title}</p>
                {singleProduct.productVarient == true && (
                  <h3 className="colors">
                    {" "}
                    Colors:
                    {uniqueColor &&
                      uniqueColor?.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className="color"
                            style={{
                              background: `${item}`,
                              outlineColor: `${
                                index == selectcolorid ? "black" : "#fafafa"
                              }`,
                            }}
                            // onClick={() => selectColor(item, index)}
                          ></span>
                        );
                      })}
                  </h3>
                )}

                <h3 className="size">
                  {singleProduct?.size?.length > 0 &&
                    (singleProduct.priceVarient == true
                      ? singleProduct.reseller_size_price.map((item, index) => {
                          return (
                            <div
                              className="sp-size-text"
                              style={{
                                background: `${
                                  priceselectid == index ? "#397d86" : "white"
                                }`,
                                color: `${
                                  priceselectid == index ? "white" : "black"
                                }`,
                              }}
                              // onClick={() => {
                              //   priceChange(item.size, index);
                              // }}
                            >
                              {item.size}
                            </div>
                          );
                        })
                      : singleProduct?.size?.map((item, index) => {
                          return (
                            <div
                              className="sp-size-text"
                              style={{
                                background: `${
                                  priceselectid == index ? "#397d86" : "white"
                                }`,
                                color: `${
                                  priceselectid == index ? "white" : "black"
                                }`,
                              }}
                              // onClick={() => {
                              //   priceChange(item.size, index);
                              // }}
                            >
                              {item.size}
                            </div>
                          );
                        }))}
                </h3>

                <h3 className="sp-qty">
                  <spna style={{ marginRight: "4px" }}>
                    Quantity: {singleProduct?.quantity}
                  </spna>
                </h3>

                <div style={{ border: "2px solid grey", padding: "15px" }}>
                  <p className="price">
                    <p>
                      Wholesale Price: {singleProduct?.reseller_price?.price}
                    </p>
                    <p>Add Profit</p>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_z2ZYQJeaBWlUL1MdxHi-SOXE42vrncqGqQ&usqp=CAU"
                      width="25px"
                      height="25px"
                      style={{ translate: "0px -2px" }}
                    />
                    <Input
                      value={profit}
                      style={{ width: "100px" }}
                      onChange={(e) => handleChangePrice(e)}
                    />
                    <p
                      className="price-range-text"
                      style={{ color: `${priceErr ? "red" : "black"}` }}
                    >{`The profit range in ${minPrice} to ${maxPrice}`}</p>
                  </p>
                  <div className="desp">Description</div>
                  <>
                    <Description
                      singleProduct={singleProduct}
                      setSingleProduct={setSingleProduct}
                    />
                  </>

                  <Button onClick={handleSubmit}>Add Product</Button>
                </div>
              </div>

              {/* <div>
                  {" "}
                  <button className="cartBtn">
                    <ShoppingCartOutlined /> Add to cart
                  </button>{" "}
                  <button className="buyBtn">Buy it now</button>{" "}
                  {/* <button className="wishBtn">
                  <HeartOutlined /> Wishlist
                </button>
                </div> */}
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="similar-product" style={{ gap: "5px" }}>
              {/* <SimilarProduct Similar_product_name="Similar items" /> */}
            </div>
          </div>
        </div>
      </>
    );
}
