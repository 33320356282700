/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Drawer, Collapse, Space } from "antd";
import { useSelector } from "react-redux";
import {
  HomeFilled,
  CloseOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  MailOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  BellOutlined,
  ShopOutlined,
  PaperClipOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import Categories from "../../home/search-results/categories";
import { useHistory, Link } from "react-router-dom";

import "./styles.scss";

const { Panel } = Collapse;
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

 
const MenuSideBar = ({ showSidebar, onCloseSidebar }) => {
  const {rsshopdata} = useSelector((state)=>{return state?.app});
  const history = useHistory();
  const [categorylist, setCategoryList] = useState([]);

  const { brands, categories, rscategory } = useSelector((state) => {
    return state?.app;
  });
  const handleCategoryNavigate = (e) =>{
    let cat = e.replace("&", "-");
    history.push(`/${rsshopdata?.shop_url}/product?category=${cat}`);
    onCloseSidebar();
  }
 

useEffect(()=>{
  let categori = [
    { label: "All Categories", value: "" },
    ...rscategory,
  ];
  let items = categori?.map((item, index) => {
    return getItem(
      <span
        onClick={() => {
          handleCategoryNavigate(item?.value);
        }}
      >
        {" "}
        {item.label}
      </span>,
      item.value,
      <MailOutlined />,
      item.children &&
        item.children.map((item, index) => {
          return getItem(
            <span
              onClick={() => {
                handleCategoryNavigate(item?.value);
              }}
            >
              {" "}
              {item.label}
            </span>,
            item.value,
            null,
            item.children &&
              item.children.map((item, index) => {
                return getItem(
                  <span
                    onClick={() => {
                      handleCategoryNavigate(item?.value);
                    }}
                  >
                    {" "}
                    {item.label}
                  </span>,
                  item.value
                );
              })
          );
        })
    );
  });

  setCategoryList(items);
},[rscategory])
 
  
  const Brands = ({ brands }) => {
    return (
      <Space size={15} direction="vertical" style={{ paddingLeft: 50 }}>
        {brands?.map((b) => (
          <Link key={b?.id} to={`/brand/${b.id}`} onClick={handleSelectOption}>
            {b?.name}
          </Link>
        ))}
      </Space>
    );
  };

  const handleSelectOption = (path) => {
    onCloseSidebar();
    path && history.push(path);
  };

  const path = useHistory();
  let linktext = path.location.pathname.split("/")[1];



  return (
    <Drawer
      placement="left"
      title={<Title onCloseSidebar={onCloseSidebar} />}
      closable={false}
      onClose={onCloseSidebar}
      visible={showSidebar}
      width={"80%"}
    >
      <Collapse
        accordion
        bordered={false}
        expandIconPosition={"right"}
        expandIcon={({ isActive }) =>
          isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
        }
        className="drawer-options-collapse"
      >
        <div className="home-option" onClick={() => handleSelectOption(`/${linktext}`)}>
          <HomeFilled style={{ marginRight: "10px" }} /> Home
        </div>
        <div>
          <Collapse>
            <Panel header=" Categories" key="1">
              <Categories categories={categorylist} />
            </Panel>
          </Collapse>
        </div>
        
        <div
          className="home-option"
          onClick={() => handleSelectOption("/contact-us")}
        >
          <MailOutlined style={{ marginRight: "10px" }} />
          Contact Us
        </div>
        <div className="home-option" onClick={onCloseSidebar}>
          <ExclamationCircleOutlined style={{ marginRight: "10px" }} />
          About Us
        </div>
      </Collapse>
    </Drawer>
  );
};

export default MenuSideBar;

const Title = ({ onCloseSidebar }) => {
  const history = useHistory();
  const userData = JSON.parse(
    localStorage.getItem("tajira-website-logged-in-user-data")
  );

  return (
    <div className="menu-sidebar-title">
      <div
        onClick={() => {
          onCloseSidebar();
          history.push("/account/my-account");
        }}
      >
        <UserOutlined />{" "}
        <span className="my-account-text">
          {userData
            ? `${userData?.first_name} ${userData?.last_name}`
            : "My Account"}
        </span>
      </div>
      <CloseOutlined className="drawer-close-icon" onClick={onCloseSidebar} />
    </div>
  );
};
