import React from "react";
import { useParams } from "react-router-dom";
import Product from "../../poshcart/product/product";
import SearchResults from "../../home/search-results";
import RsSingleProduct from "../../poshcart/reseller-singleProduct/singleProduct";
import WholeSellerProduct from "../../wholeSeller/product/product";
import ResellerProductUpload from "../../reseller/productUpload/productUpload";
import RsProducts from "../../reseller/rsProduct/rsProducts";
import LandingPage from "../../poshcart/landingPage/landingPage";
import Brands from "../../home/search-results/brands";
import ProductUpload from "../../wholeSeller/productUpload/productUpload";
import AboutUs from "../../home/about-us";
import AllShops from "../../home/all-shops";
import Blogs from "../../home/tajira-blogs";
import ResellerLanding from "../../reseller/landing";
import Checkout from "../../home/checkout";
import OrderConfirm from "../../home/order-confirm";
import ContactUs from "../../home/contact-us";
import ShoppingCart from "../../home/shopping-cart";
import SingleProduct from "../../poshcart/singleProduct/singleProduct";

export default function RouteHandler() {
  let { shopName } = useParams();

  switch (shopName) {
    case "poshcartproduct":
      return <Product />;
    case "search-results":
      return <SearchResults />;
    case "special-offer":
      return <SearchResults />;
    case "poshcartsingleproductupload":
      return <RsSingleProduct />;
    case "wholeSellerProduct":
      return <WholeSellerProduct />;
    case "resellerlandingpage":
      return <ResellerLanding />;
    case "resellerproduct":
      return <RsProducts />;
    case "landingPage":
      return <LandingPage />;
    case "reselersingleproduct":
      return <SingleProduct />;
    // case "shopping-cart":
    // return <ShoppingCart/>;
    case "checkout":
      return <Checkout />;
    // case "order-confirm":
    // return <OrderConfirm />;
    // case "brands":
    // return <Brands />;
    // case "contact-us":
    // return <ContactUs/>;
    // case "all-shops":
    // return <AllShops />;
    // case "about-us":
    // return <AboutUs/>;
    // case "blogs":
    // return <Blogs />;
    case "landingPage":
      return <LandingPage />;
    // case "landingPage":
    // return <LandingPage />;
    // case "rsproductupload" :
    // return <ResellerProductUpload/>

    default:
      return <LandingPage shopName={shopName} />;
  }
}
