import React from "react";
import "./product.scss";

import { Card, message, Pagination, Collapse, Space, Button } from "antd";
import SideBar from "../../common/sidebar";
import {
  AppstoreFilled,
  MenuOutlined,
  CaretRightOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import Card1 from "../Card/Card1";

export default function Product() {
  let productarr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  return (
    <>
      <div>
        <p></p>
        <p></p>
      </div>
      <div className="rs-maindiv">
        <div className="rs-custom-row ">
          {/* <SideBar>
          <Card className="card-shadow filters-sidebar">
            <Collapse
              defaultActiveKey={["1"]}
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header="Select Category" key="1">
                <Categories
                  // categories={categories}
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                />
              </Panel>
              <Panel header="Select Brand" key="2">
                <Brands
                  // brands={brands}
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                />
              </Panel>
              <Panel header="Price" key="3">
                <PriceSlider
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                  // setSelectedFilters={setSelectedFilters}
                />
              </Panel>
              <Panel header="Rating" key="4">
                <Rating
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                />
              </Panel>
            </Collapse>
            <br />
            <Space
              direction="vertical"
              className="w-100"
              style={{ padding: "0 20px 20px 20px" }}
              size={15}
            >
              <Button
                type="primary"
                block
                shape="round"
                // onClick={handleApplyFilter}
              >
                Apply
              </Button>
              <Button
                type="default"
                block
                shape="round"
                // onClick={handleClearFilter}
              >
                Reset Filter
              </Button>
            </Space>
          </Card>
        </SideBar> */}
          <ProductCart />
        </div>
      </div>
    </>
  );
}

export const ProductCart = () => {
  const imageUrls = Array.from(
    { length: 30 },
    (_, index) => `https://picsum.photos/200/300/?image=${index + 1}`
  );
  return (
    <>
      {imageUrls.map((item) => {
        return <Card1 item={item} />;
      })}
    </>
  );
};

