import React from "react";

export default function ReturnPolicy() {
  return (
    <div style={{marginLeft:"17px", fontSize:"14px"}}>
     * প্রোডাক্ট রিসিভ করে বাসায় নিয়ে ভিডিও সহকারে আনবক্সিং করুন <br/>
* রিটার্ন এর জন্য আমাদের কাছে ভিডিও সেন্ড করে প্রবলেম ইনফর্ম করতে হবে <br/>
* প্রোডাক্ট রিটার্ন দেয়ার সময় অবশ্যই খেয়াল রাখতে হবে প্রোডাক্ট টি যেনো বিক্রয়যোগ্য অবস্থায় থাকে এবং সাথে প্রোডাক্ট এর প্যাকেজিং যেন ভালো করে  করা হয় <br/>
* প্রোডাক্ট রিটার্ন  ক্লেইম করার সময় ৩ দিন <br/>
* প্রোডাক্ট এর ভিডিও সেন্ড করে প্রবলেম ইনফর্ম করার পরে চেক করে জানিয়ে দেয়া হবে রিটার্ন ক্লেইম গ্রহণযোগ্য হয়েছে কি না <br/>
* রিটার্ন অ্যাপ্রুভ হলে ফেরতকৃত প্রোডাক্ট হাতে পাওয়ার পরে নিউ প্রোডাক্ট পাঠিয়ে দেয়া হবে <br/>
* টাকা রিফান্ড এর জন্য রিকোয়েস্ট ক্লেইম করার পরে  অ্যাপ্রুভ হলে ৭ দিনের ভিতরে টাকা রিফান্ড পেয়ে যাবেন <br/>
* টাকা রিফান্ড এর ক্ষেত্রে যে নাম্বার থেকে অর্ডার করা হয়েছে সেই নম্বরেই টাকা রিফান্ড করা হবে অন্য কোনও নাম্বার গ্রহণযোগ্য হবে না
    </div>
  );
}
