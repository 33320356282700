/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import { message } from "antd";
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowRegisterModal,
  setShowLoginModal,
} from "./utils/redux/actions/actions";
import API from "./utils/api";
import { checkIfSecureRoute } from "./utils/helper.functions";
import "./App.scss";
import Shopifastlanding from "./components/shopifastlanding/shopifastlanding";
import RsProducts from "./components/reseller/rsProduct/rsProducts";
import RouteHandler from "./components/common/routehandler/routeHandler";
import RsSingleProduct from "./components/reseller/rsSingleproduct/rsSingleProduct";
import Header from "./components/header";
import MenuSideBar from "./components/mobile-view-components/menu-sidebar";
import MobileHeader from "./components/mobile-view-components/header";
import Footer from "./components/footer";
import Login from "./components/home/modals/login";
import Register from "./components/home/modals/register";
import Spinner from "./components/common/spinner";
import Checkout from "./components/home/checkout";
import CancelPayment from "./components/common/SuccessPayment/CancelPayment";
import Successpayment from "./components/common/SuccessPayment/Successpayment";
import LandingSingleProduct from "./components/shopifastlanding/rsSingleproduct/rsSingleProduct";

const SearchResults = React.lazy(() =>
  import("./components/home/search-results")
);

const ShoppingCart = React.lazy(() =>
  import("./components/home/shopping-cart")
);

const WholesalerDashBoard = React.lazy(() =>
  import("./components/wholesaleDashboard/src/App")
);

const AdminDashboard = React.lazy(() =>
  import("./components/admin/src/App")
);

const ResslerDashBoard = React.lazy(() =>
  import("./components/resellerDashboard/src/App")
);

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { showLoginModal, showRegisterModal, isLoggedIn } = useSelector(
    (state) => {
      return state?.app;
    }
  );

  const [showCartDrawer, setShowCartDrawer] = useState(false);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDataStringified = localStorage.getItem(
    "tajira-website-logged-in-user-data"
  );

  const onCloseLoginModal = () => {
    dispatch(setShowLoginModal(false));
  };

  const onCloseRegisterModal = () => {
    dispatch(setShowRegisterModal(false));
  };
  let { path, url } = useRouteMatch();
  const locat = useLocation();
  let dashboardpath = locat.pathname.split("/");

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/" component={Shopifastlanding} />
        {dashboardpath[1] != "wholesalerdashboard" &&
          dashboardpath[1] != "resellerdashboard" &&
          dashboardpath[1] != "admindashboard" &&
          dashboardpath[1] != "" &&
          dashboardpath[1] !=
          "product" && (
            <div id="base-component" className="base-component">
              {loading && <Spinner title="Loading Products..." />}
              <div className="sticky-header">
                <Header
                  setShowCartDrawer={() => {
                    if (isLoggedIn) {
                      setShowCartDrawer(true);
                    } else {
                      dispatch(setShowLoginModal(true));
                    }
                  }}
                />
                <MobileHeader
                  showMenuSideDrawer={() =>
                    setShowMenuDrawer(!showMenuDrawer)
                  }
                  setShowCartDrawer={() => {
                    if (isLoggedIn) {
                      setShowCartDrawer(true);
                    } else {
                      dispatch(setShowLoginModal(true));
                    }
                  }}
                />
              </div>

              <Route exact path="/:shopName" component={RouteHandler} />
              <Route
                exact
                path="/:shopName/shopping-cart"
                component={ShoppingCart}
              />

              <Route exact path="/:shopName/check-out" component={Checkout} />

              <Route exact path="/:shopName/product" component={RsProducts} />

              <Route
                exact
                path="/:shopName/payment_success"
                component={Successpayment}
              />

              <Route
                exact
                path="/:shopName/payment_cancel"
                component={CancelPayment}
              />

              <Route
                exact
                path="/:shopName/product/:productid"
                component={RsSingleProduct}
              />

              <MenuSideBar
                showSidebar={showMenuDrawer}
                onCloseSidebar={() => setShowMenuDrawer(false)}
              />
              <Login showLogin={showLoginModal} onClose={onCloseLoginModal} />
              <Register
                showRegister={showRegisterModal}
                onClose={onCloseRegisterModal}
              />

              <Footer />
            </div>
          )}

        <Route
          exact
          path="/product/:productid"
          component={LandingSingleProduct}
        />

        <Route
          exact
          path="/resellerdashboard/:id"
          component={ResslerDashBoard}
        />
        <Route
          exact
          path="/wholesalerdashboard/:id"
          component={WholesalerDashBoard}
        />

        <Route
          exact
          path="/admindashboard/:id"
          component={AdminDashboard}
        />
      </Switch>
    </Suspense>
  );
};

export default App;

