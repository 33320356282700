import React from 'react'
import Slider from "react-slick";
import './landing.scss';
import SimilarProduct from './similarproduct/similarProduct';
import Whyshopwithus from './whyshopwithus/whyshopwithus';

export default function ResellerLanding() {
 let arr = [1,1,1,1,1]
const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "landing-react-slick",
    };
    return (
      <div>
        <Slider {...settings}>

          {arr.map((item)=>{
            return (
              <>
               <div>
            <h3><img src="https://img.freepik.com/free-photo/black-friday-elements-assortment_23-2149074076.jpg?w=1480&t=st=1697476996~exp=1697477596~hmac=505b7918ec51dfb997b6d5584024885bf65a50be87d6bdbdaee3db01df2e656a" alt="banner" className='image'/></h3>
          </div>
          {/* <div>
            <h3><img src="https://img.freepik.com/free-photo/full-shot-woman-wearing-red-dress_23-2149220659.jpg?w=1380&t=st=1697477063~exp=1697477663~hmac=b3241cce389d11d8f6f335894932ba84e9d7a7419f397167ed68dd0bbf1d792b" alt="banner" className='image'/></h3>
          </div> */}
              </>
            )
          })}
         

        </Slider>

        <SimilarProduct Similar_product_name="Similar Product"/>
        <SimilarProduct Similar_product_name="Recommended Product"/>
        <SimilarProduct Similar_product_name="Best Selling product"/>
        <SimilarProduct Similar_product_name="Top Rated"/>
        
        <Whyshopwithus/>

      </div>
    );
}
