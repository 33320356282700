import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Profile {
  getUserProfile = () => {
    return axios
      .get(`${API_URL}api/v1/account/public/users/profile/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  updateUser = (payload) => {
    const url = `${API_URL}api/v1/account/public/users/profile/`;
    return axios
      .patch(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getDocuments = () => {
    return axios
      .get(`${API_URL}api/v1/auth/document/self/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  uploadDocuments = (formData) => {
    const url = `${API_URL}api/v1/auth/document/upload/`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${
          (
            JSON.parse(
              localStorage.getItem("tajira-website-logged-in-user-data")
            ) || {}
          ).token
        }`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  changeAvator = (formData, id) => {
    const url = `${API_URL}api/v1/seller/change/avatar/${id}/`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${
          (
            JSON.parse(
              localStorage.getItem("tajira-website-logged-in-user-data")
            ) || {}
          ).token
        }`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  sendEnquiry = (payload) => {
    const url = `${API_URL}api/v1/buyer/contact/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getFollowedShops = () => {
    return axios
      .get(`${API_URL}api/v1/account/public/follow-shops/`, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
