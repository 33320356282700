import React, { useState } from "react";
import { Card, Form, message, Button, Upload, Input } from "antd";
import { CameraFilled } from "@ant-design/icons";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Spinner from "../../../common/spinner";
import "./styles.scss";

const { TextArea } = Input;

const WritePost = () => {
  const blog = new API.Pages();
  const auth = new API.Auth();

  const history = useHistory();
  const [form] = Form.useForm();

  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const addBlog = (payload) => {
    getDataManager(blog?.addBlog, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        history.push("/blogs");
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content:
            x?.message ||
            error ||
            "Error ocurred while submitting return request",
        });
      }
    });
  };

  const onSubmit = (values) => {
    const payload = {
      description: values?.description,
      images: images,
      type: 0,
    };
    addBlog(payload);
  };

  const uploadFile = (file) => {
    var data = new FormData();
    file && data.append("file", file);
    data.append("user", userData?.id);

    message.loading({ content: "Uploading image", duration: 0, key: "loader" });

    getDataManager(auth?.uploadFile, setUploading, data).then((x) => {
      if (x?.status === "success") {
        setImages([...images, x?.data?.id]);
        message.success({
          content: "Image uploaded",
          duration: 2,
          key: "loader",
        });
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 0.5,
          key: "loader",
        });
      }
    });
  };
  const uploadButton = (
    <div className="upload-btn">
      <CameraFilled />
      <div>Upload</div>
    </div>
  );
  return (
    <div>
      {loading && <Spinner />}
      <Card className="write-post-card" bordered={false}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="description"
            label="Write your post here"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <TextArea
              size="large"
              className="write-post-text"
              placeholder="Write Your Comments..."
            />
          </Form.Item>
          <Form.Item
            name="images"
            rules={[{ required: true, message: "Please attach images" }]}
          >
            <Upload
              action={uploadFile}
              listType="picture-card"
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: false,
              }}
              onChange={({ fileList }) =>
                setFileList(
                  fileList.map((file) => ({ ...file, status: "done" }))
                )
              }
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={uploading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default WritePost;
