import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { Input, message } from "antd";
import { useHistory } from "react-router-dom";

import SearchResults from "./search-results";

import API from "../../../../utils/api";
import { getDataManager } from "../../../../utils/helper.functions";

import "./styles.scss";
import { useSelector } from "react-redux";

const GlobalSearch = ({compName}) => {

  const Reseler = new API.Reseler();
  const Wholesale = new API.Wholesale();
  const [searchString, setSearchString] = useState("");
  const [searching, setSearching] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [products, setProducts] = useState([]);
  const [timer, setTimer] = useState(null);
  const [stopSearch, setStopSearch] = useState(false);
  const [compname, setCompname] = useState("");

  const { rsshopdata } = useSelector((state) => {
    return state?.app;
  });
  const history = useHistory();

  const handleSearch = (value) => {
    setStopSearch(false);
    setSearchString(value);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        
        if (value !== "" && !searching) {
          if(compName == "website")
          fetchProductsListwebsite(value);
           
          if(compName == "reseller")
          fetchProductsListReseller(value);

          if(compName == "wholesaler")
          fetchProductsListWSlr(value);
        }
      }, 500)
    );
  };

  const handleSearchBtnClick = () => {
    if (searchString) {
      setSearchString("");
      setStopSearch(true);
      message.loading({ key: "loader" });
      history.push({ pathname: "/search-results", search: searchString });
    }
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchBtnClick();
    }
  };

  const fetchProductsListwebsite = (value) => {
    message.loading({ content: "Searching...", duration: 0, key: "loader" });
    getDataManager(
      Reseler?.getReselerAllSearchResults,
      setSearching,
      {},
      rsshopdata?._id,
      value
    ).then((x) => {
      if (x?.status == true) {
        
        message.success({
          content: "Products found",
          duration: 3,
          key: "loader",
        });
        if (!stopSearch) {
          setShowResults(true);
          setProducts(x?.data?.data); 
        }
      } else {
        setSearchString("");
        setShowResults(false);
        message.error({
          content: "No product found!",
          duration: 3,
          key: "loader",
        });
      }
    });
  };

  const fetchProductsListReseller = (value) => {
    message.loading({ content: "Searching...", duration: 0, key: "loader" });
    getDataManager(
      Reseler?.getReselerAllDashboardProduct,
      setSearching,
      {},
      {pageNo:1, searchtext:value}
    ).then((x) => {
      console.log("reseler serch data", x);
      if (x?.status == true) {
        
        message.success({
          content: "Products found",
          duration: 3,
          key: "loader",
        });
        if (!stopSearch) {
          setShowResults(true);
          setProducts(x?.data?.docs);        }
      } else {
        setSearchString("");
        setShowResults(false);
        message.error({
          content: "No product found!",
          duration: 3,
          key: "loader",
        });
      }
    });
  };

  const fetchProductsListWSlr = (value) => {

    console.log("wholesale search");
    message.loading({ content: "Searching...", duration: 0, key: "loader" });
    getDataManager(
      Wholesale?.getWholesaleAllProduct,
      setSearching,
      {},
      {},
      {searchtext: value, pageNo:"1"}
    ).then((x) => {
      console.log("wholesaler search product : ", x);
      if (x?.status == true) {
        
        message.success({
          content: "Products found",
          duration: 3,
          key: "loader",
        });
        if (!stopSearch) {
          setShowResults(true);
          setProducts(x?.data?.docs);        }
      } else {
        setSearchString("");
        setShowResults(false);
        message.error({
          content: "No product found!",
          duration: 3,
          key: "loader",
        });
      }
    });
  };




  return (
    <>
    {compName == "website"?
      ( <div className="global-search-base-container">
        <Input
          placeholder="search your products"
          onChange={(e) => {
            setSearchString(e.target.value);
            handleSearch(e.target.value);
          }}
          size="large"
          value={searchString}
          onKeyDown={handleEnterPress}
        />
        {!stopSearch && showResults && (
          <SearchResults
            hideSearchResults={() => setShowResults(false)}
            clearSearch={() => setSearchString("")}
            products={products}
            compname={compName}
          />
        )}
        <button className="global-search--btn" onClick={handleSearchBtnClick}>
          <SearchOutlined />
        </button>
      </div>):(
        <div className="global-search-base-container-rs-ws">
        <Input
          placeholder="search your products"
          onChange={(e) => {
            setSearchString(e.target.value);
            handleSearch(e.target.value);
          }}
          size="large"
          value={searchString}
          onKeyDown={handleEnterPress}
        />
        {!stopSearch && showResults && (
          <SearchResults
            hideSearchResults={() => setShowResults(false)}
            clearSearch={() => setSearchString("")}
            products={products}
            compname={compName}
          />
        )}
        <button className="global-search--btn" onClick={handleSearchBtnClick}>
          <SearchOutlined />
        </button>
      </div>
      )
    }
    </>
   
  );
};

export default GlobalSearch;
