import { message, notification } from "antd";

export const uaePhoneRegex =
  /^(?:\+880|00971|0)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/;
export const isNumberRegex = /^\d+$/;



export const getDataManager = async (dataApi, setLoading, payload, id, params) => {
  try {
    setLoading && setLoading(true);
    return dataApi(payload, id, params)
      .then((res) => {
        setLoading && setLoading(false);
        if (res) {
          return res;
        }
      })
      .catch((error) => {
        setLoading && setLoading(false);
        message.error({
          content: error || "Something went wrong",
          duration: 3,
        });
      });
  } catch (error) {
    setLoading && setLoading(false);
    message.error({ content: error || "Something went wrong", duration: 3 });
  }
};

export const extractContent = (html) => {
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};

export const getDateString = () => {
  const d = new Date();
  const dateString = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  return dateString;
};

export const getUnitVatAmount = (price, vat) => {
  const p = Number(price);
  const v = Number(vat);
  const pt = p * (v / 100);
  return pt.toFixed(2);
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validateNumber = (number) => {
  const regex = /^\(?0( *\d\)?){9,10}$/;
  return regex.test(String(number));
};
export const validatePassword = (pass) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return regex.test(String(pass));
};

export const toCapitalize = (str = "") => {
  const value = str.toString();
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};

export const getErrorMessage = (errors) => {
  const messages = errors && Object.keys(errors).map((key) => errors[key]);
  if (messages) {
    const isObject = typeof messages[0] === "object";
    if (isObject) {
      const innerMsg = Object.keys(messages[0]).map((key) => messages[0][key]);
      return (innerMsg || []).join(", ");
    } else {
      return (messages || []).join(", ");
    }
  }
};

export const checkIfSecureRoute = (path) => {
  const secureRoutes = [
    "/shopping-cart",
    "/checkout",
    "/order-confirm",
    "/account",
  ];
  return secureRoutes.some((r) => path.includes(r));
};

export const getSubTotal = (items) => {
  let total = 0;
  (items || []).forEach(
    (i) => (total = total + Number(i?.price) * Number(i?.qty))
  );
  return total.toFixed(2);
};

export const getVatTotal = (items) => {
  const total = getSubTotal(items) || 0;
  return Number((total * 0.05).toFixed(2));
};

export const refreshUserData = (updatedData) => {
  localStorage.setItem(
    "tajira-website-logged-in-user-data",
    JSON.stringify(updatedData)
  );
};

export const isEmpty = (strValue) => {
  if (!strValue || strValue.trim() === "" || strValue.trim().length === 0) {
    return true;
  } else {
    return false;
  }
};

export const getMembershipType = (type) => {
  if (type === 0) {
    return "General";
  } else {
    return "Premium";
  }
};

export const getOrderStatus = (type) => {
  switch (type) {
    case 0:
      return "Pending";
    case 1:
      return "Ready to Ship";
    case 2:
      return "Handover Package";
    case 3:
      return "Shipped";
    case 4:
      return "Complete";
    case 5:
      return "Cancelled";
    default:
      return "Returned";
  }
};

export const getDateLabel = (type) => {
  switch (type) {
    case 0:
      return "Placed on";
    case 1:
      return "Ready for Ship on";
    case 2:
      return "Handedover on";
    case 3:
      return "Shipped on";
    case 4:
      return "Completed on";
    case 5:
      return "Cancelled on";
    default:
      return "Returned on";
  }
};

export const getPaymentType = (type) => {
  switch (type) {
    case 0:
      return "Cash on Delivery";
    case 1:
      return "Paypal";
    case 2:
      return "Wallet";
    case 3:
      return "Reward Points";
    default:
      return "SurjoPay";
  }
};


export const Notification = (type, mesg, desp) => {
  notification[type]({
    message: mesg,
    description: desp,
  });
};