import React, { useEffect, useState } from "react";
import "./LandingProducts.scss";
import SideBar from "../../common/sidebar";
import { Button, Card, Collapse, Radio, Space, notification } from "antd";
import {
  AppstoreFilled,
  CaretRightOutlined,
  MailOutlined,
  MenuOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import Categories from "../../home/search-results/categories";
import Brands from "../../home/search-results/brands";
import PriceSlider from "../../common/price-slider";
import Rating from "../../common/rating";
import FilterBody from "../../common/filter-detail";
import { Pagination } from "antd";
import { getDataManager } from "../../../utils/helper.functions";
import API from "../../../utils/api";
import Spinner from "../../common/spinner";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setRSCategory,
  setRSLogo,
  setRSShopData,
} from "../../../utils/redux/actions/actions";
import { useHistory } from "react-router-dom";
import CustomNotification from "../../common/custom-notification/customNotification";
import ResellerCard from "../similarproduct/rslrCard";

const { Panel } = Collapse;
let routename = "";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
export default function LandingProducts() {
  const dispatch = useDispatch();
  const [siteInfo, setSiteInfo] = useState("");
  const [toggle, setToggle] = useState(false);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const Landing = new API.Landing();
  const [categoryList, setCategoryList] = useState([]);
  const [showFilterDetail, setShowFilterDetail] = useState(false);
  const [pagination, setpagination] = useState(10);

  const [CustomNotificationdata, setCustomNotificationdata] = useState({
    toggle: false,
    title: "",
    body: "",
    imageUrl: "",
  });
  const location = useLocation();
  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  const [selectedFilters, setSelectedFilters] = useState({
    max_price: 10000,
    min_price: 1,
  });

  const [params, setParams] = useState({
    pageno: "1",
    size: "20",
    category: "",
    owner: "",
    max_price: 10000,
    min_price: 1,
    sort_by: "price_asc",
  });

  const queryParams = new URLSearchParams(location.search);

  const getlandingAllProduct = async () => {
    let productdata = await getDataManager(Landing?.getLandingProduct, setLoading, {}, params)
    console.log({productdata});
    if (productdata?.code == 200) {
      setProductData(productdata?.data?.docs);
      setpagination(productdata?.data?.totalPages * 10)
    }
  };

  const handlePagination = (e) => {
    console.log(e);
    setParams({...params, pageno:e });
    setpagination(e*10);
 };


  useEffect(() => {
      getlandingAllProduct();
  }, [params]);

  if (loading) {
    return <Spinner />;
  }



  return (
    <>
      <div className="rs-all-products">
        <div className="rslr-whole-product">
          <div className="view-mode-container">
          <div className="rslr-product">
            {productData &&
              productData.length > 0 &&
              productData.map((item, index) => {
                return (
                  <div className="rslr-single-card">
                    <ResellerCard
                      item={item}
                      key={index}
                      routename={routename}
                    />
                  </div>
                );
              })}
          </div>
          <Pagination
            className="pagination"
            defaultCurrent={10}
            total={pagination}
            style={{ margin: "auto" }}
            onChange={handlePagination}
            showSizeChanger={false}
          />
        </div>
      </div>
      </div>

    </>
  )
}
