import React from "react";
import { Typography, Space } from "antd";

import Button from "./../../../common/Button";
import BaseModal from "../../../common/base-modal";

import Logo from "./../../../../images/logo.png";
import Alert from "./../../../../images/alert.png";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const RegistrationPending = ({ showModal, closeModal }) => {
  return (
    <BaseModal showModal={showModal} closeModal={closeModal} type2={true}>
      <div className="tajira-registration-pending">
        <Space direction="vertical" size={10}>
          <img
            src={Alert}
            alt="Subtraction10"
            height="40"
            style={{ marginBottom: "15px" }}
          />
          <img src={Logo} alt="Logo" />
          <Title level={4} style={{ marginTop: "10px" }}>
            Registration Pending!
          </Title>
          <div className="verification-inputs-render">
            <Paragraph>
              Your account in under review, for further information please
              contact Arab Tools!
            </Paragraph>
          </div>

          <Button
            text="Okay"
            onClick={closeModal}
            style={{ marginTop: "10px" }}
          />
        </Space>
      </div>
    </BaseModal>
  );
};

export default RegistrationPending;
