import React from "react";
import { Typography, Button } from "antd";

import BaseModal from "./../../../common/base-modal";

import accountCreatedIcon from "./../../../../images/successfully-verified.png";

import "./styles.scss";

const { Title } = Typography;

const AccountCreated = ({ showModal, closeModal }) => {
  return (
    <BaseModal showModal={showModal} closeModal={closeModal}>
      <div className="tajira-account-created">
        <img src={accountCreatedIcon} alt="" height={150} />
        <br />
        <br />
        <Title level={3}>Successfully verified!</Title>
        <br />
        <Title level={5}>Now you are a part of our valuable family</Title>
        <br />
        <Button type="primary" block onClick={closeModal} size="large">
          Home
        </Button>
      </div>
    </BaseModal>
  );
};

export default AccountCreated;
