import axios from "axios";
import { API_URL, TOKEN, AMTokenfunc } from "../constants";

export default class AdminDashboard {
  adminSignin = (payload) => {
    const url = `${API_URL}/api/admin/auth/login`;
    return axios
      .post(url, payload)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
         console.log("admin signin data========", response);


          if(response.data.data.admin.role == "admin")
          {
            localStorage.setItem(
              "shopyfastbd-am-token",
              JSON.stringify(response.data)
            );
          }


          localStorage.setItem(
            "shopyfastbd-token",
            JSON.stringify(response.data)
          );
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };


  getadminuserlist = ( payload,id, params) => {
    const url = `${API_URL}/api/admin/user/list?role=${params?.compname}&search`;
    const bearerToken = AMTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };


}
