import React, { useEffect, useState } from "react";
import { Avatar, Badge, Dropdown, Popover, Space } from "antd";
import {
  ShoppingCartOutlined,
  MenuOutlined,
  HeartOutlined,
  UserOutlined,
  MessageOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import GlobalSearch from "../../header/mini-components/global-search";
import { RxAvatar } from "react-icons/rx";

import comanyLogo from "../../../images/shopyfast-logo.png";

import {
  clearRedux,
  setIsLoggedIn,
  setShowLoginModal,
} from "../../../utils/redux/actions/actions";

import "./styles.scss";
import { useHistory } from "react-router-dom";
import { API_URL, CSUserInfo } from "../../../utils/constants";
import { useLocation } from "react-router-dom";
import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";

const Header = (props) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.app);
  const userdata = CSUserInfo();
  const history = useHistory();
  let indexpage = window.location.href.split("/")[3];
  const handleLogout = () => {
    localStorage.removeItem("shopyfastbd-cs-token");
    dispatch(setIsLoggedIn(false));
    dispatch(clearRedux());
    history.push(`/${indexpage}`);
  };

  const handleViewAccount = () => {
    history.push("/account/my-account");
  };

  const handleViewMessages = () => {
    history.push("/all-messages");
  };




  const items = [
    {
      label: <div>Profile</div>,
      key: '0',
    },
    {
      label:  <div>Settings</div>,
      key: '1',
    },
    {
      label: <div
      onClick={handleLogout}
    >
      Log Out
    </div>,
      key: '3',
    },
  ];

  return (
    <header className="mobile-header-base-container">
      <section className="top-section">
        <div className="logo-container">
          <MenuOutlined
            className="menu-icon"
            onClick={props.showMenuSideDrawer}
          />
          <Logo />
        </div>

        <Space size={5} className="space-div">
          <Cart />
          {userdata?.role == "customer" ? (
         
<Dropdown
menu={{
  items,
}}
trigger={['click']}
>
<a onClick={(e) => e.preventDefault()}>
  <Space>
  <Avatar size={30}>
                  {userdata.email.substring(0, 2).toUpperCase()}
                </Avatar>
  </Space>
</a>
</Dropdown>
          ) : (
            <RxAvatar
              className="login-btn"
              onClick={() => dispatch(setShowLoginModal(true))}
              style={{ width: "25px", height: "25px" }}
            />
          )}
          {/* <WishList /> */}
          &nbsp;&nbsp;
        </Space>
      </section>
      <section className="middle-section">
        <GlobalSearch  compName="website"/>
      </section>
    </header>
  );
};

export default Header;

const Logo = () => {
  const { logo } = useSelector((state) => {
    return state?.app;
  });
  const path = useHistory();
  let linktext = path.location.pathname.split("/")[1];
  let logotext = linktext.toUpperCase();
  return (
    <Link to={`/${linktext}`}>
      {/* <img className="company-logo" src={comanyLogo} alt="logo" /> */}
      {logo ? (
        <img className="company-logo" src={`${API_URL}/${logo}`} alt="logo" />
      ) : (
        <span
          style={{
            fontSize: "25px",
            fontWeight: "750",
            letterSpacing: ".5px",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          {logotext}
        </span>
      )}
    </Link>
  );
};

const Cart = () => {
  let quantity = 0;
  const params = useLocation();
  const { logo } = useSelector((state) => {
    return state?.app;
  });
  const dispatch = useDispatch();
  let shopname = window.location.href.split("/")[3];

  const { cartItems } = useSelector((state) => state.app);
  const Reseler = new API.Reseler();
  const [loading, setLoading] = useState(false);
  const [cartCount, setcartCount] = useState(0);

  const fetchCartItems = () => {
    getDataManager(Reseler?.userGetCart, setLoading).then((x) => {
      if(x==undefined)
      {
       return;
      }
      if (x?.data?.is_empty_cart == true) {
        setcartCount(0);
      } else {
        setcartCount(1);
      }
    });
  };

  useEffect(() => {
    fetchCartItems();
  }, [cartItems]);

  return (
    <div className="cart">
      <Link to={`/${shopname}/shopping-cart`}>
        <Badge count={cartCount} showZero={true}>
          <ShoppingCartOutlined />
        </Badge>
      </Link>
    </div>
  );
};

const WishList = () => {
  const { wishlistItems } = useSelector((state) => state.app);

  let quantity = 0;

  (wishlistItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });

  return (
    <Link className="wishlist" to="/account/wishlist">
      <Badge count={quantity} showZero={true}>
        <HeartOutlined />
      </Badge>
    </Link>
  );
};
