import React from "react";
import SimilarProduct from "../singleproduct/singleproduct";
import "./product.scss";
export default function WholeSellerProduct() {
  return (
    <div>
      {/* <div className="main-div">
        <div className="ws-bannerImgDiv">
          <img src="https://synder.com/blog/wp-content/uploads/sites/5/2022/05/practices-for-an-ecommerce-business-boost-1.jpg" alt="" className="ws-bannerImg" />
        </div>
        <div className="ws-logoDiv">
            <img src="https://marketplace.canva.com/EAFNSCeT-9I/1/0/1600w/canva-black-and-gold-classy-minimalist-circular-name-logo-w23QQUdwfh0.jpg" className="ws-logo"/>
        </div>
        
      </div> */}
      <SimilarProduct />
    </div>
  );
}
