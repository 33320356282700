import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Products {
  getProducts = (searchParams = "") => {
    return axios
      .get(`${API_URL}api/v1/store/public/products/${searchParams}`, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getStoreProducts = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/store/public/products/?store=${
          payload?.store
        }&limit=${payload?.limit || 50}&offset=${payload?.offset || 0}&name=${
          payload?.name || ""
        }`,
        {
          headers: TOKEN
            ? {
                Authorization: `Token ${TOKEN}`,
              }
            : {},
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getStoreDetail = (id) => {
    return axios
      .get(`${API_URL}api/v1/account/public/shops/${id}/`, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getFilters = (payload) => {
    return axios
      .get(
        `${API_URL}api/v1/inventory/home/filters/mobile/?search_term=${payload}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getProductDetail = (id) => {
    return axios
      .get(`${API_URL}api/v1/store/public/products/${id}/`, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getProductReview = (id) => {
    return axios
      .get(`${API_URL}api/v1/store/public/${id}/product-rating-review/`, {
        headers: {
          Authorization: `Token ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  addProductReview = (payload) => {
    const url = `${API_URL}api/v1/store/public/rating-review/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getStoreList = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/account/public/shops/?limit=${
          payload?.limit || 50
        }&offset=${payload?.offset || 0}`,
        {
          headers: TOKEN
            ? {
                Authorization: `Token ${TOKEN}`,
              }
            : {},
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  followShop = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/account/public/follow-shops/add_remove_follow_shop/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getSimilarProducts = (payload = {}) => {
    let url = `${API_URL}api/v1/store/public/products/?limit=${
      payload?.limit || 12
    }&offset=${payload?.offset || 0}
    &related_products=${payload?.id}&min_price=${
      payload?.min_price || ""
    }&max_price=${payload?.max_price || ""}&rating=${payload?.rating || ""}`;
    return axios
      .get(url, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };
}
