import React, { useState } from "react";
import { Card, message, Row, Col, Space, Checkbox } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import AddNoteModal from "../../modals/add-note";
import Spin from "../../../common/spinner";

import "./styles.scss";
import { API_URL } from "../../../../utils/constants";
import { useDispatch } from "react-redux";

const CartItem = ({
  item = {},
  deleteItemFromCart,
  changeQuantity,
  handleSelection,
  selectedCartItems = [],
  updatingQuantity,
  qty,
}) => {
  const [showNoteModal, setShowNoteModal] = useState(false);

  const handleAddNote = () => {
    setShowNoteModal(true);
  };

  const dispatch = useDispatch();

  const handleCloseNoteModal = () => {
    setShowNoteModal(false);
  };
  const { total_price, title, size, price, item_count, image } = item;

  return (
    <Card bordered={false} className="shopping-cart-item">
      <div key={"15"} className="cart-item">
        <Row gutter={[2, 2]} align="middle">
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <img src={`${API_URL}/${image}`} alt="img" className="cart-img" />
          </Col>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="cart-item-title" style={{ marginLeft: "12px" }}>
              {title?.length > 30 ? title?.substring(0, 25) : title}
            </div>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className="cart-item-price">{price * qty}</div>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Space direction="vertical" size={10} align="end">
              <div
                className="cart-item-delete-btn"
                onClick={() => deleteItemFromCart()}
              >
                <DeleteOutlined />
              </div>
              <AddProduct
                id={"15"}
                count={item_count}
                changeQuantity={changeQuantity}
                qty={qty}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[2, 2]} align="middle">
          <Col xs={24}>
            <div style={{ padding: "10px 0px 0px 0px", fontSize:"17px",fontWeight:600 }}>
           {item?.color !="" &&  <span>
                <span>Color:</span>
                <span style={{marginLeft:"5px", fontWeight:700, fontSize:"18px"}}>{item?.color}</span>{" "}
                </span>
              }
             {item?.size !="" && <span style={{marginLeft:"15px"}}>
                <span>Size:</span>
                <span style={{marginLeft:"5px", fontWeight:700, fontSize:"18px"}}>{item?.size}</span>{" "}
                </span>
              }
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default CartItem;

const AddProduct = ({ id, count, changeQuantity, updatingQuantity, qty }) => {
  return (
    <>
      <div className="cart-item-add-product">
        {updatingQuantity && <Spin />}
        <div
          className="decrement"
          onClick={() => {
            if (qty > 1) {
              changeQuantity(-1);
            } else {
              message.warning({
                content: `Minimum quantity must be 1`,
              });
            }
          }}
        >
          -
        </div>
        <div className="count">{qty}</div>
        <div className="increment" onClick={() => changeQuantity(1)}>
          +
        </div>
      </div>
    </>
  );
};
