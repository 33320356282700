import axios from "axios";
import { API_URL } from "../constants";

export default class Checkout {
  getProfessions = () => {
    return axios
      .get(`${API_URL}api/v1/buyer/profession/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };
}
