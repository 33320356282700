import React from "react";
import { Card, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getSubTotal } from "../../../utils/helper.functions";

import "./styles.scss";

const OrderSummary = ({ item, qty, shippingCharge }) => {
  const history = useHistory();
  const location = useLocation();
  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  const { checkoutCartItems } = useSelector((state) => state.app);

  const productsTotal = Number(getSubTotal(checkoutCartItems));

  return (
    <Card bordered={false}>
      <div className="tajira-order-summary">
        <header className="header">Payment Summary</header>
        <section className="upper-section">
        <div className="other-info">
            <span className="label">Item:</span>
            <span>BDT {item?.total_price * qty} 
           
            </span>
          </div>

          <div className="other-info" >
            <span className="label">Shipping Charge:</span>
            <span>BDT {shippingCharge} 
           
            </span>
          </div>
          
          <hr/>
          <div style={{float:"right"}}>
           
            <span style={{ fontSize:"18px", fontWeight:"bolder", paddingBottom:"20px"}}><span style={{marginRight:"50px",}}>Subtotal:</span>   BDT {item?.total_price * qty + shippingCharge} 
           
            </span>
          </div>
        </section>
        {/* <section className="lower-section">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button onClick={() => history.push(`/${routepaths[1]}/check-out`)}>
              Proceed to Checkout
            </Button>
          </div>
        </section> */}
      </div>
    </Card>
  );
};

export default OrderSummary;
