import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import "./imageUpload.scss";
import API from "../../../utils/api";
import Spinner from "../../common/spinner";
import { getDataManager } from "../../../utils/helper.functions";
// import Messages from "../../../utils/api/Messages";
import { Select, Space, Radio } from "antd";
import { API_URL } from "../../../utils/constants";

const ImageUpload = ({setSingleProduct, singleProduct }) => {
  const Upload = new API.Upload();
  const [loading, setLoading] = useState(false);
  const handleImageChange = (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let formdata = new FormData();
      formdata.append("originalImage", files[i]);
      formdata.append("croppedImage", files[i]);

      getDataManager(Upload?.uploadImage, setLoading, formdata).then((x) => {
        if (x?.status === true) {
         
          setSingleProduct((singleProduct) => ({
            ...singleProduct,
            images: [
              ...singleProduct.images,
              { image: x.data.croppedImage, color: "", mainImage: false, is_rslr_image: true, isSeleced: false },
            ],
          }));

          // dispatch(setInfoPage(x?.data));
        } else {
          // Messages.error({
          //   content: "Error ocurred while loading info",
          // });
        }
      });
    }
  };


  // const handleImageColorChange = (e, Colorindex) => {
  //   setWholeSellProduct({
  //     ...wholwSellProduct,
  //     images: wholwSellProduct.images.map((item, index) => {
  //       if (index == Colorindex) {
  //         return {
  //           ...item,
  //           color: e,
  //         };
  //       } else return item;
  //     }),
  //   });
  // };

  // const handleSelectMainImage = (e, mainIndex) => {
  //   setWholeSellProduct({
  //     ...wholwSellProduct,
  //     images: wholwSellProduct.images.map((item, index) => {
  //       if (index == mainIndex) {
  //         return {
  //           ...item,
  //           mainImage: true,
  //         };
  //       } else
  //         return {
  //           ...item,
  //           mainImage: false,
  //         };
  //     }),
  //   });
  // };
  // const handleimagedelete = (deleteIndex) => {
  //   setWholeSellProduct({
  //     ...wholwSellProduct,
  //     images: wholwSellProduct.images.filter(
  //       (item, index) => index != deleteIndex
  //     ),
  //   });
  // };

  // const colorArray = wholwSellProduct.colors.map((item) => {
  //   return { value: item, label: item };
  // });
  return (
    <div className="ws-img-upload">
      {loading && <Spinner />}
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageUpload;
