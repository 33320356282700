import React, { useEffect, useState } from "react";
import { getDataManager } from "../../../utils/helper.functions";
import API from "../../../utils/api";
import Spinner from "../spinner";
import { useLocation } from 'react-router-dom';
import CancelPayment from "./CancelPayment";
import { ImCancelCircle } from "react-icons/im";
import './cancelpayment.scss';
import { SlLike } from "react-icons/sl";



export default function Successpayment() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const order_id = localStorage.getItem("shopyfastorderid");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const order_id = queryParams.get('invoice_id');


  const Reseler = new API.Reseler();

  const checkSuccessStatus = () => {
    getDataManager(Reseler?.checkpaymentstatus, setLoading, {},order_id ).then((x) => {
      if(x.status == true)
      {
        setSuccess(true);
      }
      else
      {
        setSuccess(false);
      }
      
    });
  };

  // useEffect(()=>{
  //   checkSuccessStatus();
  // },[])

  if (loading) {
    <Spinner />;
  }

  return (
    <>
  
   
   <SuccessStatus/>
    

  </>
  
  );
}




const SuccessStatus=()=> {
  return (
    <div className='p_cancel_div'>
     <SlLike style={{color:"green"}} className='p_cancel_icon'/>

        <div className='p_cancel_sorry'>Congratulations</div>
        <div className='p_cancel_text'>Your payment is successfull</div>
        
        </div>
  )
}
