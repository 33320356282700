import React from 'react'
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

var jodit_2;
const config = {
    minHeight: 500,
    enableDragAndDropFileToEditor: true,
    enter: "BR",
    buttons: ["bold", "italic", "underline", "strikethrough", "ul", "ol", "left", "font", "fontsize", "brush", "link", "symbol", "table", "eraser", "source"],
    askBeforePasteHTML:false,
    events: {
        afterInit: (instance) => {
            jodit_2 = instance;
        },
    }

};


const Editor = ({ value, handleUpdate }) => {

    return (
        <JoditEditor
            value={value}
            config={config}
            onBlur={handleUpdate} />
    )
}

export default Editor