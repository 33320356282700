/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  message,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";

import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isNumberRegex } from "../../../../utils/helper.functions";

import BaseModal from "../../../common/base-modal";

import {
  setIsLoggedIn,
  setShowLoginModal,
  setShowRegisterModal,
  setUserData,
} from "../../../../utils/redux/actions/actions";
import CodeVerificationModal from "../code-verification";
import AccountCreated from "../account-created";
import RegistrationPending from "../registration-pending";
import ForgotPasswordModal from "../forgot-password";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";
import { useWindowSize } from "../../../../utils/custom-hooks";

import "./styles.scss";
import Spinner from "../../../common/spinner";

const Login = ({ showLogin, onClose, showModal, closeModal }) => {
  const Wholesalerdashboard = new API.WholesalerDashboard();

  const [form] = Form.useForm();

  const { Option } = Select;

  const dispatch = useDispatch();

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("+880");
  const [showRegistrationPending, setShowRegistrationPending] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [statusInfo, setStatusInfo] = useState("individual");
  const [isRegistrationPending, setIsRegistrationPending] = useState(false);
  const [loading ,setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState({
    nameError: false,
    passwordErr: false,
  });

  const history = useHistory();

  const size = useWindowSize();

  const Notification = (type, mesg, desp) => {
    notification[type]({
      message: mesg,
      description: desp,
    });
  };

  const handleRefresh = () => {
    window.location.reload(false);
  };

  const Userlogin = () => {
    getDataManager(Wholesalerdashboard?.wholesalerSignin, setLoading, {
      email: email,
      password: password,
    })
      .then((res) => {
        if (res?.status === true) {
          Notification("success", `Logged in successfully`);
          dispatch(setShowLoginModal(false));
          window.location.reload();

        } else {
       
          if (res.response.status == 404) {
            Notification("error", `Email not found`);
            seterror({ ...error, nameError: true, passwordErr: false });
          }
          if (res.response.status == 403) {
            Notification("error", "Password is not correct");
            seterror({ ...error, passwordErr: true, nameError: false });
          }
          if (res.response.status == 422) {
            Notification("error", "Invalid credentials");
            seterror({ ...error, passwordErr: true, nameError: true });
          }
        }
      })
      .catch((err) => {});
  };

  const resendVerificationCode = () => {
    const auth = new API.Auth();

    const payload = {
      email: email,
    };

    getDataManager(auth?.resendVerificationCode, null, payload).then((x) => {
      if (x?.status === "success") {
        message.success({
          content: x?.message || "Verification code has been sent",
          duration: 3,
        });
      } else {
        message.error({
          content: x?.message[0] || "Error ocurred in signing up",
          duration: 3,
        });
      }
    });
  };

  const onCloseAccountCreateModal = () => {
    setShowAccountCreatedModal(false);
    if (statusInfo !== "individual") {
      setShowRegistrationPending(true);
    } else {
      history.push("/");
    }
  };

  const onCloseRegistrationModal = () => {
    setShowRegistrationPending(false);
    history.push("/");
  };

if(loading)
  {
    <Spinner/>
  }

  return (
    <>
      <BaseModal type2={true} showModal={showLogin} closeModal={onClose}>
        <section className="tajira-login-modal">
          <div className="login-modal-left-section">
            <Form form={form} layout="horizontal" >
              <header className="header">Please Login to Continue</header>
              <Form.Item
                label="Email"
                name="username"
                rules={[
                  {
                   
                    message: "Please enter Email",
                  },
                 
                  { required: true, message: "Please enter your email" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Please enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined />}
                  placeholder="Please enter Password"
                  visible={true}
                  onChange={(e) => setPassword(e.target.value)}
                  onCopy={() => ""}
                  visibilityToggle={false}
                  onPaste={() => ""}
                  size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <div className="captcha"></div>
              <div
                className="forgot-password"
                onClick={() => setShowForgotPassword(true)}
              >
                Forgot your password?
              </div>
              <Form.Item>
                <Button type="primary" block onClick={Userlogin} style={{marginBottom:"10px"}}>
                  Login
                </Button>

                <Button
                  className="tajira-base-button outlined"
                  block
                  onClick={() => {
                    onClose();
                    dispatch(setShowRegisterModal(true));
                  }}
                >
                  Create an Account
                </Button>
              </Form.Item>
            </Form>
           
          </div>

          {/* {size.width > 800 && (
            <div className="login-modal-right-section">
              <header className="heading">Are you a new user in</header>
              <div className="company-title">Tajira.com?</div>
              <div className="its-easy">Welcome to the Tajira!</div>
              <div className="description">
                By creating an account you will be able to shop faster, be up to
                date on an order's status, and keep track of the orders you have
                previously made.
              </div>
              <Button
                block
                className="tajira-base-button outlined"
                onClick={() => {
                  onClose();
                  history.push("/register");
                }}
              >
                Sign Up Now!
              </Button>
            </div>
          )} */}
        </section>
      </BaseModal>

      {/* <ForgotPasswordModal
        showModal={showForgotPassword}
        onClose={() => setShowForgotPassword(!showForgotPassword)}
        setShowVerificationModal={(s) => setShowVerificationModal(s)}
      />
      <CodeVerificationModal
        showModal={showVerificationModal}
        closeModal={() => setShowVerificationModal(false)}
      />
      <AccountCreated
        showModal={showAccountCreatedModal}
        closeModal={onCloseAccountCreateModal}
      />
      <RegistrationPending
        showModal={showRegistrationPending}
        closeModal={onCloseRegistrationModal}
      /> */}
    </>
  );
};

export default Login;
