/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Card, message, Checkbox, Button, Row, Col } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import CartItem from "./cart-item";
import ArabsToolsBreadcrumb from "../../common/breadcrumb";
import Spin from "../../common/spinner";
import OrderSummary from "../order-summary";
import EmptyCart from "../empty-cart";
import API from "../../../utils/api";
import { getDataManager, getErrorMessage,
} from "../../../utils/helper.functions";
import {
  setCheckoutCartItems,
  setCartItems,
  setShowLoginModal,
} from "../../../utils/redux/actions/actions";

import "./styles.scss";
import { CSbearerToken, CSTokenfunc } from "../../../utils/constants";
import { useLocation } from "react-router-dom";
import Checkout from "../checkout";

const Reseler = new API.Reseler();

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const [cartitem, setcartitem] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [qty, setqty] = useState(1);
  const [shippingCharge, setShippingCharge] = useState(80);
  const usertoken = CSTokenfunc();

  const location = useLocation();
  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  const fetchCartItems = () => {
    getDataManager(Reseler?.userGetCart, setLoading).then((x) => {
      if (x?.data?.is_empty_cart == true) {
        setcartitem({});
      } else {
        setcartitem({ ...x?.data });
      }
    });
  };

  const { cartItems } = useSelector((state) => state.app);


  const deleteItemFromCart = () => {

    getDataManager(Reseler?.userDeleteCart, setLoading).then((x) => {
      settoggle(!toggle);
      dispatch(setCartItems(!cartItems));
    });
  };


  const changeQuantity = (id) => {
    if (CSbearerToken.length < 20) {
      Notification("warning", "Login to add to cart");
      dispatch(setShowLoginModal(true));
      return;
    }

    getDataManager(Reseler?.userAddToCart, setLoading, {
      item_id: cartitem?.item_id,
      item_count: qty + id,
    }).then((res) => {
      if (res?.code == 200) {
       setqty(res?.data?.item_count);
      }
    });
  };

  const cartfromlogin = () => {
    dispatch(setShowLoginModal(true));
  };

  useEffect(() => {
    if (usertoken.length > 20) {
      fetchCartItems();
    }
  }, [toggle]);


  if (usertoken.length < 20) {
    return (
      <div className="cart_login">
        login to continue
        <br />
        <div className="cart_login_span" onClick={cartfromlogin}>
          Click here to login
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <ArabsToolsBreadcrumb paths={["Shopping Cart"]} />
      <br /> */}
      <div className="tajira-shopping-cart">
        {loading && <Spin />}
        {Object.keys(cartitem).length != 0 ? (
          <>
            <Row gutter={[20, 20]}>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={16}
                xl={16}
                xxl={16}
                className="left-section"
              >
                <Space size={[10, 20]} direction="vertical">
                  {cartitem && (
                    <CartItem
                      key={"1"}
                      item={cartitem}
                      deleteItemFromCart={deleteItemFromCart}
                      changeQuantity={changeQuantity}
                      qty={qty}
                    />
                  )}
                </Space>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                xxl={8}
                className="right-section"
              >
                <OrderSummary item={cartitem} qty={qty} shippingCharge={shippingCharge}/>
              </Col>
            </Row>
            <Checkout shippingCharge={shippingCharge} setShippingCharge={setShippingCharge}/>
          </>
        ) : (
          <EmptyCart />
        )}
      </div>
      <br />
    </>
  );
};

export default ShoppingCart;
